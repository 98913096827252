/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    forecastExistsApi,
    getSingleForecast,
    postForecastApi, putForecastApi,

} from "@/helpers/api";

export const state = {
    forecastSuccess: null,
    forecastErr: null,
    forecastSingleData: null,
    forecastExists: null,

};
export const getters = {

    forecastSuccess: state => state.forecastSuccess,
    forecastErr: state => state.forecastErr,
    forecastSingleData: state => state.forecastSingleData,
    forecastExists: state => state.forecastExists,
};

export const mutations = {
    setForecastSuccess(state, newValue) {
        state.forecastSuccess = newValue
    },
    setForecastErr(state, newValue) {
        state.forecastErr = newValue
    },
    setSingleForecast(state, newValue) {
        state.forecastSingleData = newValue
    },
    setForecastExist(state, newValue) {
        state.forecastExists = newValue
    },
};

export const actions = {
    postForecast({commit}, payload) {
        postForecastApi(payload).then(res => {
            commit('setForecastSuccess', res.data)
        }, (error) => {
            commit('setForecastErr', error.data)
        })
    },
    putForecast({commit}, payload) {
        putForecastApi(payload).then(res => {
            commit('setForecastSuccess', res.data);
        }, (error) => {
            commit('setForecastErr', error.data)
        })
    },
    fetchSingleForecast({commit}, id) {
        getSingleForecast(id).then(res => {
            commit('setSingleForecast', res.data)
        })
    },
    isForecastExists({commit}, payload) {
        forecastExistsApi(payload).then(res => {
            commit('setForecastExist', res.data)
        }, () => {
            commit('setForecastExist', true)
        })
    },

    clearForecast({commit}) {
        commit('setForecastSuccess', null);
        commit('setForecastErr', null);
        commit('setSingleForecast', null);
    },
    clearIsForecastExist({commit}) {
        commit('setForecastExist', null);
    },
};
