/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getListItemsApi,
    deleteListItemApi,
    postListItemApi,
    getSingleListItem,
    putListItemApi,
    deleteAllListItemApi, getCustomListItemRecord
} from "../../helpers/api";

export const state = {
    listitems: [],
    listitemSuccess: null,
    listItemSuccessWithoutRefresh: {},
    listItemUpdateWithoutRefresh: {},
    listitemsDeleteStatus: {},
    allListItemsDeleteStatus: {},
    listitemErr: {},
    listItemSingleData: {},
    customListItemsDownload: null,
};

export const getters = {
    listitems: state => state.listitems,
    listitemSuccess: state => state.listitemSuccess,
    listitemsDeleteStatus: state => state.listitemsDeleteStatus,
    allListItemsDeleteStatus: state => state.allListItemsDeleteStatus,
    listitemErr: state => state.listitemErr,
    listItemSingleData: state => state.listItemSingleData,
    listItemSuccessWithoutRefresh: state => state.listItemSuccessWithoutRefresh,
    listItemUpdateWithoutRefresh: state => state.listItemUpdateWithoutRefresh,
    customListItemsDownload: state => state.customListItemsDownload
};

export const mutations = {
    setListItems(state, newValue) {
        state.listitems = newValue
    },
    setListItemDeleteStatus(state, newValue) {
        state.listitemsDeleteStatus = newValue
    },
    setListItemErr(state, newValue) {
        state.listitemErr = newValue
    },
    setListItemSuccess(state, newValue) {
        state.listitemSuccess = newValue
    },
    setListItemSuccessWithoutRefresh(state, newValue) {
        state.listItemSuccessWithoutRefresh = newValue
    },
    setListItemUpdateWithoutRefresh(state, newValue) {
        state.listItemUpdateWithoutRefresh = newValue
    },
    setSingleListItem(state, newValue) {
        state.listItemSingleData = newValue
    },
    setAllListItemDeleteSuccess(state, newValue) {
        state.allListItemsDeleteStatus = newValue
    },
    setExportCustomListItemRecord(state, newValue) {
        state.customListItemsDownload = newValue
    },
};

export const actions = {
    fetchListItems({commit}, payload) {
        getListItemsApi(payload).then(res => {
            commit('setListItems', res.data)
        })
    },
    deleteListItem({commit}, payload) {
        deleteListItemApi(payload).then(res => {
            commit('setListItemDeleteStatus', res.data)
        }, (error) => {
            commit('setListItemDeleteStatus', error.data)
        })
    },
    deleteAllListItems({commit}, payload) {
        deleteAllListItemApi(payload).then(res => {
            commit('setAllListItemDeleteSuccess', res.data)
        }, (error) => {
            commit('setAllListItemDeleteSuccess', error.data)
        })
    },
    postListItem({commit}, payload) {
        postListItemApi(payload).then(res => {
            (Object.hasOwn(payload, 'diff_state') ? commit('setListItemSuccessWithoutRefresh', res.data) : commit('setListItemSuccess', res.data));
        }, (error) => {
            commit('setListItemErr', error)
        })
    },
    exportCustomListItemRecord({commit}, payload) {
        getCustomListItemRecord(payload).then(res => {
            commit('setExportCustomListItemRecord', res.data)
        })
    },
    fetchSingleListItem({commit}, id) {
        getSingleListItem(id).then(res => {
            commit('setSingleListItem', res.data)
        })
    },
    putListItem({commit}, payload) {
        putListItemApi(payload).then(res => {
            (Object.hasOwn(payload, 'diff_state') ? commit('setListItemUpdateWithoutRefresh', res.data) : commit('setListItemSuccess', res.data));
        }, (error) => {
            commit('setListItemErr', error.data)
        })
    },

    clearListItems({commit}) {
        console.log("IN this.clearList();")
        commit('setListItemSuccessWithoutRefresh', null);
        commit('setListItemUpdateWithoutRefresh', null);
        commit('setListItemSuccess', null);
        commit('setListItemDeleteStatus', null);
        commit('setSingleListItem', null);
        commit('setListItems', null);
        commit('setAllListItemDeleteSuccess', null);
        commit('setExportCustomListItemRecord', null);
    }, clearListItemErr({commit}) {
        commit('setListItemErr', {});
    },
    clearAllDeleteListItems({commit}) {
        commit('setAllListItemDeleteSuccess', null);
        commit('setListItemDeleteStatus', null);
    }
};
