/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getRoles,
    postRolesApi,
    putRolesApi,
    getRoleApi,
    deleteRolesApi
} from "../../helpers/api";

export const state = {
    roles: [],
    roleSuccess: null,
    roleErr: null,
    roleSingleData: {},
    rolesDeleteStatus: {},
};

export const getters = {
    roles: state => state.roles,
    roleSuccess: state => state.roleSuccess,
    roleErr: state => state.roleErr,
    roleSingleData:  state => state.roleSingleData,
    rolesDeleteStatus:  state => state.rolesDeleteStatus

};

export const mutations = {
    setRoles(state, newValue) {
        state.roles = newValue
    },

    setRoleSuccess(state, newValue) {
        state.roleSuccess = newValue
    },

    setRolesDeleteStatus(state, newValue) {
        state.rolesDeleteStatus = newValue
    },

    setRoleSingleSuccess(state, newValue) {
        state.roleSingleData = newValue
    },

    setRoleErr(state, newValue) {
        state.roleErr = newValue
    },

};

export const actions = {
    fetchRoles({commit}, payload) {
        getRoles(payload).then(res => {
            commit('setRoles', res.data)
        })
    },
    getSingleRole({commit}, payload) {
        getRoleApi(payload).then(res => {
            commit('setRoleSingleSuccess', res.data)
        })
    },
    postRoles({commit}, payload) {
        postRolesApi(payload).then(res => {
            commit('setRoleSuccess', res.data)
        }, (error) => {
            commit('setRoleErr', error.data);
        })
    },
    deleteRoles({commit}, payload) {
        deleteRolesApi(payload).then(res => {
            commit('setRolesDeleteStatus', res.data)
        }, (error) => {
            commit('setRolesDeleteStatus', error.data)
        })
    },
    putRoles({commit}, payload) {
        putRolesApi(payload).then(res => {
            commit('setRoleSuccess', res.data)
        }, (error) => {
            commit('setRoleErr', error.data);
        })
    },
    clearDeleteRole({commit}) {
        commit('setRolesDeleteStatus', null)

    },
    clearRole({commit}) {
        commit('setRoleSuccess', null);
        commit('setRoleErr', null);
        commit('setRoles', null);
    },

};
