/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getCreatives,
    postCreativeApi,
    postCreativeVideoApi,
    putCreativeApi,
    deleteCreativeApi,
    deleteBulkCreativeApi,
    getCreativeSingleDataApi,
    getCreativeExistingAssetsApi,
    getCreativeExistingCompanionAssetsApi,
    getCreativeExistingBannerAssetsApi,
    getCreativeExistingAudioAssetsApi,
    getCreativeAddOnApi,
    postCreativeFindAndReplaceApi,
    creativeExists,
    duplicateCreativeApi,
    getCreativeApprovalQueues,
    getCreativeApprovalHistories, getCreativesRecord,
    postCreativeUrlAssetApi,
    fetchSingleCreativeUrlAssetApi,
    putCreativeUrlAssetApi
} from "../../helpers/api";

export const state = {
    creatives: [],
    existingAssets: [],
    existingCompanionAssets: [],
    existingBannerAssets: [],
    existingAudioAssets: [],
    creativeApprovalQueues: [],
    creativeApprovalHistory: [],
    creativeSuccess: {},
    creativeSuccessPost: {},
    creativeUrlAssetSuccess: {},
    creativeUrlAssetSuccessPut: {},
    videoSuccessPost: {},
    creativeErr: {},
    creativeFindAndReplaceErr: {},
    creativeDeleteStatus: null,
    creativeBulkDeleteStatus: null,
    creativeSingleData: {},
    singleCreativeUrlAsset: {},
    creativeAddOn: {},
    creativeFindAndReplace: {},
    creativeSuccessPut: {},
    creativeExists: null,
    creativeSuccessWithoutRefresh: null,
    creativesDownload: null

};

export const getters = {
    creatives: state => state.creatives,
    creativeSuccess: state => state.creativeSuccess,
    creativeSuccessPost: state => state.creativeSuccessPost,
    creativeUrlAssetSuccess: state => state.creativeUrlAssetSuccess,
    creativeUrlAssetSuccessPut: state => state.creativeUrlAssetSuccessPut,
    videoSuccessPost: state => state.videoSuccessPost,
    existingAssets: state => state.existingAssets,
    existingCompanionAssets: state => state.existingCompanionAssets,
    existingBannerAssets: state => state.existingBannerAssets,
    existingAudioAssets: state => state.existingAudioAssets,
    creativeErr: state => state.creativeErr,
    creativeDeleteStatus: state => state.creativeDeleteStatus,
    creativeBulkDeleteStatus: state => state.creativeBulkDeleteStatus,
    creativeSingleData: state => state.creativeSingleData,
    singleCreativeUrlAsset: state => state.singleCreativeUrlAsset,
    creativeAddOn: state => state.creativeAddOn,
    creativeFindAndReplace: state => state.creativeFindAndReplace,
    creativeSuccessPut: state => state.creativeSuccessPut,
    creativeExists: state => state.creativeExists,
    creativeApprovalQueues: state => state.creativeApprovalQueues,
    creativeApprovalHistory: state => state.creativeApprovalHistory,
    creativeSuccessWithoutRefresh: state => state.creativeSuccessWithoutRefresh,
    creativesDownload: state => state.creativesDownload,
    creativeFindAndReplaceErr: state => state.creativeFindAndReplaceErr,
};

export const mutations = {
    setCreatives(state, newValue) {
        state.creatives = newValue
    },
    setExistingAssets(state, newValue) {
        state.existingAssets = newValue
    },
    setExistingCompanionAssets(state, newValue) {
        state.existingCompanionAssets = newValue
    },
    setExistingBannerAssets(state, newValue) {
        state.existingBannerAssets = newValue
    },
    setExistingAudioAssets(state, newValue) {
        state.existingAudioAssets = newValue
    },
    setCreativeAddOn(state, newValue) {
        state.creativeAddOn = newValue
    },
    setCreativeErr(state, newValue) {
        state.creativeErr = newValue
    },
    setCreativeFindAndReplaceErr(state, newValue) {
        state.creativeFindAndReplaceErr = newValue
    },
    setCreativeSuccess(state, newValue) {
        state.creativeSuccess = newValue
    },
    setCreativeSuccessPost(state, newValue) {
        state.creativeSuccessPost = newValue
    },
    setCreativeUrlAssetSuccess(state, newValue) {
        state.creativeUrlAssetSuccess = newValue
    },
    setCreativeUrlAssetSuccessPut(state, newValue) {
        state.creativeUrlAssetSuccessPut = newValue
    },
    setCreativeSuccessPut(state, newValue) {
        state.creativeSuccessPut = newValue
    },
    setVideoSuccessPost(state, newValue) {
        state.videoSuccessPost = newValue
    },
    setCreativeDeleteStatus(state, newValue) {
        state.creativeDeleteStatus = newValue
    },
    setCreativeBulkDeleteStatus(state, newValue) {
        state.creativeBulkDeleteStatus = newValue
    },
    setCreativeSingleDataSuccess(state, newValue) {
        state.creativeSingleData = newValue
    },
    setSingleCreativeUrlAsset(state, newValue) {
        state.singleCreativeUrlAsset = newValue
    },
    setCreativeFindAndReplace(state, newValue) {
        state.creativeFindAndReplace = newValue
    },
    setCreativeExist(state, newValue) {
        state.creativeExists = newValue
    },
    setCreativeApprovalQueues(state, newValue) {
        state.creativeApprovalQueues = newValue
    },
    setCreativeApprovalHistories(state, newValue) {
        state.creativeApprovalHistory = newValue
    },
    setCreativeSuccessWithoutRefresh(state, newValue) {
        state.creativeSuccessWithoutRefresh = newValue
    },
    setExportCreativeRecord(state, newValue) {
        state.creativesDownload = newValue
    },

};

export const actions = {
    fetchCreatives({commit}, payload) {
        getCreatives(payload).then(res => {
            commit('setCreatives', res.data)
        })
    },
    fetchExistingAssets({commit}, payload) {
        getCreativeExistingAssetsApi(payload).then(res => {
            commit('setExistingAssets', res.data)
        })
    },
    fetchExistingCompanionAssets({commit}, payload) {
        getCreativeExistingCompanionAssetsApi(payload).then(res => {
            commit('setExistingCompanionAssets', res.data)
        })
    },
    fetchExistingBannerAssets({commit}, payload) {
        getCreativeExistingBannerAssetsApi(payload).then(res => {
            commit('setExistingBannerAssets', res.data)
        })
    },
    fetchExistingAudioAssets({commit}, payload) {
        getCreativeExistingAudioAssetsApi(payload).then(res => {
            commit('setExistingAudioAssets', res.data)
        })
    },
    fetchCreativeApprovalQueues({commit}, payload) {
        getCreativeApprovalQueues(payload).then(res => {
            commit('setCreativeApprovalQueues', res.data)
        })
    },
    fetchCreativeApprovalHistories({commit}, payload) {
        getCreativeApprovalHistories(payload).then(res => {
            commit('setCreativeApprovalHistories', res.data)
        })
    },
    exportCreativeRecord({commit}, payload) {
        getCreativesRecord(payload).then(res => {
            commit('setExportCreativeRecord', res.data)
        })
    },
    fetchCreativeAddOn({commit}, payload) {
        getCreativeAddOnApi(payload).then(res => {
            commit('setCreativeAddOn', res.data)
        })
    },
    isCreativeExists({commit}, payload) {
        creativeExists(payload).then(res => {
            commit('setCreativeExist', res.data)
        }, () => {
            commit('setCreativeExist', true)
        })
    },
    postCreative({commit}, payload) {
        postCreativeApi(payload).then(res => {
            commit('setCreativeSuccessPost', res.data)
        }, (error) => {
            commit('setCreativeErr', error.data)
        })
    },
    postCreativeUrlAsset({commit}, payload) {
        postCreativeUrlAssetApi(payload).then(res => {
            commit('setCreativeUrlAssetSuccess', res.data)
        }, (error) => {
            commit('setCreativeUrlAssetErr', error.data)
        })
    },
    putCreativeUrlAsset({commit}, payload) {
        putCreativeUrlAssetApi(payload).then(res => {
            commit('setCreativeUrlAssetSuccessPut', res.data)
        }, (error) => {
            commit('setCreativeUrlAssetErr', error.data)
        })
    },
    fetchSingleCreativeUrlAsset({commit}, payload) {
        fetchSingleCreativeUrlAssetApi(payload).then(res => {
            commit('setSingleCreativeUrlAsset', res.data)
        })
    },
    duplicateCreative({commit}, payload) {
        duplicateCreativeApi(payload).then(res => {
            commit('setCreativeSuccessPost', res.data)
        }, (error) => {
            commit('setCreativeErr', error.data)
        })
    },
    postCreativeVideo({commit}, payload) {
        postCreativeVideoApi(payload).then(res => {
            commit('setVideoSuccessPost', res.data)
        }, (error) => {
            commit('setCreativeErr', error.data)
        })
    },
    postCreativeFindAndReplace({commit}, payload) {
        postCreativeFindAndReplaceApi(payload).then(res => {
            commit('setCreativeFindAndReplace', res.data)
        }, (error) => {
            commit('setCreativeFindAndReplaceErr', error.data)
        })
    },
    putCreative({commit}, payload) {
        putCreativeApi(payload).then(res => {
            (Object.hasOwn(payload, 'diff_state') ? commit('setCreativeSuccessWithoutRefresh', res.data) : commit('setCreativeSuccessPut', res.data));
        }, (error) => {
            commit('setCreativeErr', error.data)
        })
    },
    deleteCreative({commit}, payload) {
        deleteCreativeApi(payload).then(res => {
            commit('setCreativeDeleteStatus', res.data)
        }, (error) => {
            commit('setCreativeDeleteStatus', error.data)
        })
    },
    deleteBulkCreative({commit}, payload) {
        deleteBulkCreativeApi(payload).then(res => {
            commit('setCreativeBulkDeleteStatus', res.data)
        }, (error) => {
            commit('setCreativeBulkDeleteStatus', error.data)
        })
    },
    clearDeleteCreative({commit}) {
        commit('setCreativeDeleteStatus', null)
        commit('setCreativeBulkDeleteStatus', null)

    },
    fetchSingleCreative({commit}, payload) {
        getCreativeSingleDataApi(payload).then(res => {
            commit('setCreativeSingleDataSuccess', res.data)
        })
    },
    clearCreative({commit}) {
        commit('setCreativeSuccessWithoutRefresh', null);
        commit('setCreatives', null);
        commit('setCreativeSuccess', null);
        commit('setCreativeErr', null);
        commit('setCreativeSingleDataSuccess', null);
        commit('setSingleCreativeUrlAsset', null);
        commit('setCreativeFindAndReplaceErr', null);
        commit('setCreativeAddOn', null);
        commit('setCreativeSuccessPost', null);
        commit('setCreativeUrlAssetSuccess', null);
        commit('setCreativeUrlAssetSuccessPut', null);
        commit('setCreativeFindAndReplace', null);
        commit('setCreativeSuccessPut', null);
        commit('setCreativeDeleteStatus', null);
        commit('setCreativeApprovalQueues', null);
        commit('setCreativeApprovalHistories', null);
        commit('setExportCreativeRecord', null);
    },
    clearCreativeList({commit}) {
        commit('setCreativeSuccess', null);
        commit('setCreativeSuccessPut', null);
    },
    clearCreativeSuccessWithoutRefresh({commit}) {
        commit('setCreativeSuccessWithoutRefresh', null);
    },
    clearExistingAssets({commit}) {
        commit('setExistingAssets', null);
    },
    clearExistingAudioAssets({commit}) {
        commit('setExistingAudioAssets', null);
    },
    clearExistingCompanionAssets({commit}) {
        commit('setExistingCompanionAssets', null);
    },
    clearExistingBannerAssets({commit}) {
        commit('setExistingBannerAssets', null);
    },
    clearIsCreativeExist({commit}) {
        commit('setCreativeExist', null);
    }

};
