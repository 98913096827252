/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {getCreativeSegmentsApi} from "../../helpers/api";

export const state = {
    creativeSegments: [],
    creativeSegmentsErr: {},
};

export const getters = {
    creativeSegments: state => state.creativeSegments,
    creativeSegmentsErr: state => state.creativeSegmentsErr,
};

export const mutations = {
    setCreativeSegments(state, newValue) {
        state.creativeSegments = newValue
    },
    setCreativeSegmentsErr(state, newValue) {
        state.creativeSegmentsErr = newValue
    },

};

export const actions = {
    fetchCreativeSegments({commit}, payload) {
        getCreativeSegmentsApi(payload).then(res => {
            commit('setCreativeSegments', res.data)
        })
    },
    clearCreativeSegments({commit}) {
        commit('setCreativeSegments', null);
    }
};
