/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getPixels,
    getPixelPages
} from "../../helpers/api";

export const state = {
    pixels: [],
    pixelPages: []
};
export const getters = {
    pixels: state => state.pixels,
    pixelPages: state => state.pixelPages
};

export const mutations = {
    setPixels(state, newValue) {
        state.pixels = newValue
    },
    setPixelPages(state, newValue) {
        state.pixelPages = newValue
    },
};

export const actions = {
    fetchPixels({commit}, payload) {
        let isPayload = payload.action ? payload.action : "setPixels";
        if (payload.action) {
            delete payload.action;
        }
        getPixels(payload).then(res => {
            commit(isPayload, res && res.data ? res.data : []  )
        })
    },
    fetchPixelPages({commit}, payload) {
        let isPayload = payload.action ? payload.action : "setPixelPages";
        if (payload.action) {
            delete payload.action;
        }
        getPixelPages(payload).then(res => {
            commit(isPayload, res && res.data ? res.data : []  )
        })
    },
};
