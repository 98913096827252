/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */

import appConfig from "../app.config.json";
import store from '@/state/store'
import routes from './routes'
import { createWebHistory, createRouter } from "vue-router";
import {getUserPermissions, toTitleCase} from "@/helpers/util"


// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => {
//     console.log("pushing", err.name);
//     if (err.name !== 'NavigationDuplicated') {
//         throw err
//     }
//   });
// }

// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
//     if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
//     return originalReplace.call(this, location).catch(err => {
//         console.log("pushing", err.name);
//         if (err.name !== 'NavigationDuplicated') {
//             throw err
//         }
//       });
// }

// Vue.use(VueRouter)
// Vue.use(VueMeta, {
//     // The component option name that vue-meta looks for meta info on.
//     keyName: 'page',
// })

const router = createRouter({
    history: createWebHistory(),
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: "history",
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    }
});

// Before each route evaluates...
if(!process.env.VUE_APP_DSP_AUTHENTICATION_ENABLED){
    router.beforeEach((routeTo, routeFrom, next) => {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
            // Check if auth is required on this route
            // (including nested routes).
            const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

            // If auth isn't required for the route, just continue.
            if (!authRequired) return next()

            // If auth is required and the user is logged in...
            if (store.getters['auth/loggedIn']) {
                // Validate the local user token...
                return store.dispatch('auth/validate').then((validUser) => {
                    // Then continue if the token still represents a valid user,
                    // otherwise redirect to login.
                    validUser ? next() : redirectToLogin()
                })
            }

            // If auth is required and the user is NOT currently logged in,
            // redirect to login.
            redirectToLogin()

            // eslint-disable-next-line no-unused-vars
            // eslint-disable-next-line no-inner-declarations
            function redirectToLogin() {
                // Pass the original route to the login component
                next({name: 'login', query: {redirectFrom: routeTo.fullPath}})
            }
        } else {

        
            const publicPages = ['/login', '/register', '/forgot-password', '/recoverpwd'];
            const authpage = !publicPages.includes(routeTo.path);
            const loggeduser = localStorage.getItem('user');

            if (authpage && !loggeduser) {
                return next('/login');
            }

            next();
        }
    })
}


router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).

    if(process.env.VUE_APP_IS_MAINTENANCE && process.env.VUE_APP_IS_MAINTENANCE == 'true') {
        if(routeTo.path != '/maintenance') {
            return window.location = '/maintenance';
        }
    }

    const skipRoute = ['/login', '/register', '/forgot-password', '/recoverpwd', '/no-permission','/404','/creative-specs','/logout','/','/advertising-standards'];
    if(!skipRoute.includes(routeTo.path)) {

        let routeType = routeTo.params.type
        if(routeType == 'sales') {
            routeType = 'sale';
        }

        if(routeTo.meta && routeTo.meta.permission){
            // I don't understand why we still need this and all permission lookup are all pointed to a user which is wrong
            // Each page and api end-point has it's own role/permissions.
            // By Having this separate role/permission we be able to easily control each pages.
            let permissionString = routeTo?.meta?.permission ? routeTo.meta.permission.replace(/\*_/g, `${routeType}_`) : routeTo.meta.permission;
            switch(permissionString){
                case 'user': {
                    const permissions = getUserPermissions(permissionString);
                    if (!permissions.includes('read')) {
                        return window.location = '/no-permission';
                    }
                    if (((routeTo.params.action == undefined && routeType === 'add') || (routeTo.params.action === 'add')) && !permissions.includes('write')) {
                        return window.location = '/no-permission';
                    } 
                }
                break 
            }
            
        }
        
    }



    try {
        // For each matched route...
        for (const route of routeTo.matched) {
           
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            // Complete the redirect.
                            next(...args)
                            reject(new Error('Redirected'))
                        } else {
                            resolve()
                        }
                    })
                } else {
                    // Otherwise, continue resolving the route.
                    resolve()
                }
            })
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return
    }

    //console.log("replace", routeTo);

    
    let action = `${window.location.href.includes("/edit") || routeTo.fullPath.includes("/edit") ? 'Edit' : (routeTo.fullPath.includes("/duplicate") ? 'Duplicate' : (routeTo.fullPath.includes("/add") ? 'New' : null))}`
   
    let type = `${window.location.href.includes("/edit") || routeTo.fullPath.includes("/edit") ? 'Edit' : (routeTo.fullPath.includes("/duplicate") ? 'Duplicate' :  (routeTo.fullPath.includes("/add") ? 'New' : (routeTo.fullPath.includes("/view") ? 'View' : null)  ))}`
    
    if(window.location.href.includes("/attribution/") || routeTo.fullPath.includes("/attribution/")) {
        type = routeTo.params && routeTo.params.type ? toTitleCase(routeTo.params.type) : "";
    }
    
    let title = routeTo && routeTo.meta && routeTo.meta.title ? routeTo.meta.title.replace(`{type}`, type) : "";
    if(action != null) {
        title = title.replace(`{action}`, action)
    }

    //console.log("replace", routeTo, window.location.href);

    document.title = title + " | " + appConfig.title;
    // If we reach this point, continue resolving the route.
    next()
})




export default router
