/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getBuyingGroups,
    buyingGroupExists,
    buyingGroupDomainExists,
    postBuyingGroupApi,
    putBuyingGroupApi,
    deleteBuyingGroupApi,
    getBuyingGroupRecord
} from "../../helpers/api";

export const state = {
    buying_groups: [],
    buyingGroupSucccess: null,
    buyingGroupSuccessWithRefresh: null,
    buyingGroupDeleteStatus: {},
    buyingGroupErr: null,
    buyingGroupExists: null,
    buyingGroupDomainExists: null,
    buyingGroupDownload: null,

};
export const getters = {
    buying_groups: state => state.buying_groups,
    buyingGroupDeleteStatus: (state) => state.buyingGroupDeleteStatus,
    buyingGroupExists: (state) => state.buyingGroupExists,
    buyingGroupDomainExists: (state) => state.buyingGroupDomainExists,
    buyingGroupSucccess: (state) => state.buyingGroupSucccess,
    buyingGroupSuccessWithRefresh: (state) => state.buyingGroupSuccessWithRefresh,
    buyingGroupErr: (state) => state.buyingGroupErr,
    buyingGroupDownload: (state) => state.buyingGroupDownload
};

export const mutations = {
    setBuyingGroups(state, newValue) {
        state.buying_groups = newValue
    },
    setBuyingGroupExist(state, newValue) {
        state.buyingGroupExists = newValue
    },
    setBuyingGroupDomainExist(state, newValue) {
        state.buyingGroupDomainExists = newValue
    },
    setBuyingGroupSuccess(state, newValue) {
        state.buyingGroupSucccess = newValue
    },
    setBuyingGroupSuccessWithRefresh(state, newValue) {
        state.buyingGroupSuccessWithRefresh = newValue
    },
    setBuyingGroupErr(state, newValue) {
        state.buyingGroupErr = newValue
    },
    setBuyingGroupDeleteStatus(state, newValue) {
        state.buyingGroupDeleteStatus = newValue
    },
    setExportBuyingGroupRecord(state, newValue) {
        state.buyingGroupDownload = newValue
    },
};

export const actions = {
    fetchBuyingGroups({commit}, payload) {
        let isPayload = payload.action ? payload.action : "setBuyingGroups";
        if (payload.action) {
            delete payload.action;
        }
        getBuyingGroups(payload).then(res => {
            commit(isPayload, res.data)
        })
    },
    isBuyingGroupExists({commit}, payload) {
        buyingGroupExists(payload).then(res => {
            commit('setBuyingGroupExist', res.data)
        }, () => {
            commit('setBuyingGroupExist', true)
        })
    },
    isBuyingGroupDomainExist({commit}, payload) {
        buyingGroupDomainExists(payload).then(res => {
            commit('setBuyingGroupDomainExist', res.data)
        }, () => {
            commit('setBuyingGroupDomainExist', true)
        })
    },
    postBuyingGroup({commit}, payload) {
        postBuyingGroupApi(payload).then(res => {
            commit('setBuyingGroupSuccess', res.data)
        }, (error) => {
            commit('setBuyingGroupErr', error.data)
        })
    },
    putBuyingGroup({commit}, payload) {
        putBuyingGroupApi(payload).then(res => {
            (Object.hasOwn(payload, 'diff_state') ? commit('setBuyingGroupSuccessWithRefresh', res.data) : commit('setBuyingGroupSuccess', res.data));
        }, (error) => {
            commit('setBuyingGroupErr', error.data)
        })
    },
    deleteBuyingGroup({commit}, payload) {
        deleteBuyingGroupApi(payload).then(res => {
            commit('setBuyingGroupDeleteStatus', res.data)
        }, (error) => {
            commit('setBuyingGroupDeleteStatus', error.data)
        })
    },
    clearIsBuyingGroupsExist({commit}) {
        commit('setBuyingGroupExist', null);
    },
    clearIsBuyingGroupsDomainExist({commit}) {
        commit('setBuyingGroupDomainExist', null);
    },
    exportBuyingGroupRecord({commit}, payload) {
        getBuyingGroupRecord(payload).then(res => {
            commit('setExportBuyingGroupRecord', res.data)
        })
    },
    clearBuyingGroups({commit}) {
        commit('setBuyingGroupSuccessWithRefresh', null);
        commit('setBuyingGroupSuccess', null);
        commit('setBuyingGroupErr', null);
        commit('setBuyingGroups', null);
        commit('setExportBuyingGroupRecord', null);
    }
};
