/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getIO,
    getSingleIO,
    getIOMeta,
    removeIO,
    postIO,
    putIO,
    getIOComments,
    postIOComments,
    generateIOApi,
    isDspIdExistApi,
    getZipCodeListApi,
    getCampignTypes,
    getCampaignPurchaser,
    getListOfCampaigns,
    getIOEnvelopeStatus
} from "@/helpers/api";

export const state = {
    ioList: [],
    advertiserList: [],
    ioSubmitSuccess: {},
    ioSubmitError: {},
    ioCommentsList: [],
    ioCommentsResponse: {},
    ioSingleResponse: {},
    ioDeleteStatus: {},
    manufacturerList: [],
    buyingList: [],
    campaignTypeList: [],
    campaignPurchaserList: [],
    zipCodeList: [],

    countyList: [],
    stateList: [],
    dmaList: [],
    congressList: [],
    campaignList: [],
    

    ioCampaignSuccess: null,
    isDspIdExistFlag: null,
    envelopeStatus: null
};
export const getters = {
    ioList: state => state.ioList,
    advertiserList: state => state.advertiserList,
    ioSubmitSuccess: state => state.ioSubmitSuccess,
    ioSubmitError: state => state.ioSubmitError,
    ioCommentsList: state => state.ioCommentsList,
    ioCommentsResponse: state => state.ioCommentsResponse,
    ioSingleResponse: state => state.ioSingleResponse,
    ioDeleteStatus: state => state.ioDeleteStatus,
    buyingList: state => state.buyingList,
    manufacturerList: state => state.manufacturerList,
    ioCampaignSuccess: state => state.ioCampaignSuccess,
    isDspIdExistFlag: state => state.isDspIdExistFlag,
    zipCodeList: state => state.zipCodeList,
    envelopeStatus: state => state.envelopeStatus,
    countyList: state => state.countyList,
    stateList: state => state.stateList,
    dmaList: state => state.dmaList,
    congressList: state => state.congressList,
    campaignTypeList: state => state.campaignTypeList,
    campaignPurchaserList: state => state.campaignPurchaserList,
    campaignList: state => state.campaignList
};

export const mutations = {
    setIO(state, newValue) {
        state.ioList = newValue
    },
    setIOMetaAdvertiser(state, newValue) {
        state.advertiserList = newValue
    },
    setIOMetaBuying(state, newValue) {
        state.buyingList = newValue
    },
    setIOMetaManufacturer(state, newValue) {
        state.manufacturerList = newValue
    },
    setIOSubmitError(state, newValue) {
        state.ioSubmitError = newValue
    },
    setIOSubmitSuccess(state, newValue) {
        state.ioSubmitSuccess = newValue
    },
    setIOComments(state, newValue) {
        state.ioCommentsList = newValue
    },
    setIOCommentsReponse(state, newValue) {
        state.ioCommentsResponse = newValue
    },
    setIOSingleReponse(state, newValue) {
        state.ioSingleResponse = newValue
    },
    setIoCampaignSuccess(state, newValue) {
        state.ioCampaignSuccess = newValue
    },
    setIoDeleteStatus(state, newValue) {
        state.ioDeleteStatus = newValue
    },
    setZipCodeList(state, newValue) {
        state.zipCodeList = newValue
    },

    setCountyList(state, newValue) {
        state.countyList = newValue
    },
    setStateList(state, newValue) {
        state.stateList = newValue
    },
    setDmaList(state, newValue) {
        state.dmaList = newValue
    },
    setCongressList(state, newValue) {
        state.congressList = newValue
    },


    setCampaignTypeList(state, newValue) {
        state.campaignTypeList = newValue
    },
    setCampaignPurchaserList(state, newValue) {
        state.campaignPurchaserList = newValue
    },
    setCampaignList(state, newValue) {
        state.campaignList = newValue
    },
    setIoEnvelopeStatus(state, newValue) {
        state.envelopeStatus = newValue
    }
};

export const actions = {
    isDspIdExist({commit}, payload) {
        isDspIdExistApi(payload).then(res => {
            let result = res.data;
            commit('setIO', result)
        })
    },
    fetchIO({commit}, payload) {
        getIO(payload).then(res => {
            let result = res.data;
            commit('setIO', result)
        })
    },
    fetchZipCodeList({commit}, payload) {
        getZipCodeListApi(payload).then(res => {
            let result = res.data;
            commit('setZipCodeList', result)
        })
    },
    fetchSingleIO({commit}, payload) {
        getSingleIO(payload).then(res => {
            let result = res.data;
            commit('setIOSingleReponse', result)
        })
    },
    fetchCampaignList({commit}, payload) {
        getListOfCampaigns(payload).then(res => {
            let result = res.data;
            commit('setCampaignList', result)
        }, (error) => {
            commit('setCampaignList', {})
        })
    },
    fetchIOComments({commit}, payload) {
        getIOComments(payload).then(res => {
            let result = res.data;
            commit('setIOComments', result)
        })
    },
    fetchCampaignTypes({commit}) {
        getCampignTypes().then(res => {
            let result = res.data;
            commit('setCampaignTypeList', result)
        })
    },
    fetchCampaignPurchaser({commit}) {
        getCampaignPurchaser().then(res => {
            let result = res.data;
            commit('setCampaignPurchaserList', result)
        })
    },
    saveIOComments({commit}, payload) {
        postIOComments(payload).then(res => {
            let result = res.data;
            commit('setIOCommentsReponse', result)
        })
    },
    fetchIOMeta({commit}, payload) {
        getIOMeta(payload).then(res => {
            let result = res.data;
            if(payload.type == 'advertiser' ) {
                commit('setIOMetaAdvertiser', result)
            }
            if(payload.type =='buying') {
                commit('setIOMetaBuying', result)
            }
            if(payload.type =='congress') {
                commit('setCongressList', result)
            }
            if(payload.type =='state') {
                commit('setStateList', result)
            }
            if(payload.type =='dma') {
                commit('setDmaList', result)
            }
            if(payload.type =='counties') {
                commit('setCountyList', result)
            }
            if(payload.type =='manufacturer') {
                commit('setIOMetaManufacturer', result)
            }
        })
    },
    deleteIO({commit}, payload) {
        removeIO(payload).then(res => {
            let result = res.data;
            commit('setIoDeleteStatus', result)
        })
    },
    saveIO({commit}, payload) {
        postIO(payload).then(res => {
            let result = res.data;
            commit('setIOSubmitSuccess', result)
        }, (error) => {
            commit('setIOSubmitError', error.data)
        })
    },
    generateCampaignIO({commit}, payload) {
        generateIOApi(payload).then(res => {
            commit('setIoCampaignSuccess', {...res, ...{type: 'generate'}})
        }, (error) => {
            commit('setIoCampaignSuccess', error.data)
        })
    },
    featchIOEnvelopeStatus({commit}, payload) {
        getIOEnvelopeStatus(payload).then(res => {
            let result = res.data;
            commit('setIoEnvelopeStatus', result);
        }, (error) => {
            commit('setIoEnvelopeStatus', {})
        })
    },
    clearCampaignIO({commit}) {
        commit('setIoCampaignSuccess', null)
    },
    updateIO({commit}, payload) {
        putIO(payload).then(res => {
            let result = res.data;
            let type = 'update';
            if(payload.response_type) {
                type = payload.response_type;
                delete payload.response_type;
            }
            commit('setIOSubmitSuccess', {...res, ...{type: type}});
        }, (error) => {
            commit('setIOSubmitError', error.data)
        })
    },
    clearIO({commit}) {
        commit('setIOSubmitSuccess', null);
        commit('setIOSubmitError', null);
    }
};
