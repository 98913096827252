/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {getRetargetingSegmentsApi, postRetargetingSegmentApi, putRetargetingSegmentApi} from "../../helpers/api";

export const state = {
    retargetingSegments: [],
    retargetingSegmentSuccess: null,
    retargetingSegmentErr: null,
};

export const getters = {
    retargetingSegments: state => state.retargetingSegments,
    retargetingSegmentSuccess: state => state.retargetingSegmentSuccess,
    retargetingSegmentErr: state => state.retargetingSegmentErr,
};

export const mutations = {
    setRetargetingSegments(state, newValue) {
        state.retargetingSegments = newValue
    },
    setRetargetingSegmentSuccess(state, newValue) {
        state.retargetingSegmentSuccess = newValue
    },
    setRetargetingSegmentErr(state, newValue) {
        state.retargetingSegmentErr = newValue
    },

};

export const actions = {
    fetchRetargetingSegments({commit}, payload) {
        getRetargetingSegmentsApi(payload).then(res => {
            commit('setRetargetingSegments', res.data)
        })
    },
    postRetargetingSegment({commit}, payload) {
            postRetargetingSegmentApi(payload).then(res => {
                commit('setRetargetingSegmentSuccess', res.data)
            }, (error) => {
                commit('setRetargetingSegmentErr', error.data)
            })
    },
    putRetargetingSegment({commit}, payload) {
        putRetargetingSegmentApi(payload).then(res => {
            commit('setRetargetingSegmentSuccess', res.data)
        }, (error) => {
            commit('setRetargetingSegmentErr', error.data)
        })
},
    clearRegargetingSegments({commit}) {
        commit('setRetargetingSegments', null);
    }
};
