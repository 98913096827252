/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
export const state = {
    dateFilter: {},
    headerTitle: null,
    isCompare: false
};

export const getters = {
    dateFilter: (state) => state.dateFilter,
    headerTitle: (state) => state.headerTitle,
    isCompare: (state) => state.isCompare,
};

export const mutations = {
    setDateFilter(state, newValue) {
        state.dateFilter = newValue
    },
    setTitle(state, newValue) {
        state.headerTitle = newValue
    },
    setCompare(state, newValue) {
        state.isCompare = newValue
    }
};

export const actions = {
    updateDateRange({commit}, payload) {
        commit('setDateFilter', payload)
    },
    setTitle({commit}, payload) {
        commit('setTitle', payload)
    },
    setCompare({commit}, payload) {
        commit('setCompare', payload)
    },
};
