/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import store from '@/state/store'


export default [
    {
        path: '/',
        name: 'default',
        permission: "default",
        meta: {
            authRequired: true,
        },
        component: () => import('../views/dashboards/default'),
    },
    {
        path: '/cs-tool',
        name: 'cs_tool',
        meta: {
            permission: "automation",
            authRequired: true,
            title: "Automation"
        },
        component: () => import('../views/cs-tool/list'),
    },
    {
        path: '/ios',
        name: 'ios',
        meta: {
            authRequired: true,
            permission: "io",
        },
        component: () => import('../views/io/list'),
    },
    {
        path: '/creative-specs',
        name: 'creative_specs',
        meta: {
            authRequired: true,
            title: 'Creative Specification'
        },
        component: () => import('../views/creative-specs/index'),
    },
    {
        path: '/advertising-standards',
        name: 'advertising-standards',
        meta: {
            authRequired: true,
            title: 'Advertising Standards'
        },
        component: () => import('../views/advertising-standards/index'),
    },
    {
        path: '/advertisers',
        name: 'advertiser',
        meta: {
            permission: "advertiser",
            authRequired: true,
            title: "Advertisers"
        },
        component: () => import('../views/manage/advertisers/list'),
    },
    {
        path: '/buying-group',
        name: 'buying_group',
        meta: {
            permission: "buying_group",
            authRequired: true,
            title: 'Buying Group'
        },
        component: () => import('../views/manage/buying_group/list'),
    },
    {
        path: '/parent-companies',
        name: 'parent_company',
        meta: {
            permission: "customer",
            authRequired: true,
            title: 'Parent Company'
        },
        component: () => import('../views/manage/parent_company/list'),
    },
    {
        path: '/parent-company/:type',
        name: 'add_parent_company',
        meta: {
            permission: "customer",
            authRequired: true,
            title: `{type} Parent Company`
        },
        component: () => import('../views/manage/parent_company/add'),
    },
    {
        path: '/manufacturers',
        name: 'manufacturer',
        meta: {
            permission: "manufacturer",
            authRequired: true,
            title: 'Manufacturer'
            
        },
        component: () => import('../views/manage/manufacturers/list'),
    },
    {
        path: '/manufacturer/:type',
        name: 'add_manufacturer',
        meta: {
            permission: "manufacturer",
            authRequired: true,
            title: `{type} Manufacturer`
        },
        component: () => import('../views/manage/manufacturers/add'),
    },
    {
        path: '/presets',
        name: 'presets',
        meta: {
            permission: "preset",
            authRequired: true,
            title: "Presets"
        },
        component: () => import('../views/admin/presets/list'),
    },
    /* {
        path: '/modifiers',
        name: 'modifiers',
        meta: {
            permission: "preset",
            authRequired: true,
        },
        component: () => import('../views/admin/modifiers/list'),
    },
    {
        path: '/modifier/:type',
        name: 'modifier',
        meta: {
            permission: "preset",
            authRequired: true,
        },
        component: () => import('../views/admin/modifiers/add'),
    }, */
    {
        path: '/preset/:type',
        name: 'add_preset',
        meta: {
            permission: "preset",
            authRequired: true,
            title: `{type} Preset`
        },
        component: () => import('../views/admin/presets/add'),
    },
    {
        path: '/advertisers/:advertiser_id/campaigns/:in_campaign_ids/line-items/bulk_edit_targeting',
        name: 'bulk_edit_targeting',
        meta: {
            permission: "advertiser",
            authRequired: true,
        },
        component: () => import('../views/bulk-edit-targeting/index'),
    },
    {
        path: '/advertisers/:advertiser_id/campaigns/bulk_modifier_capping',
        name: 'bulk_modifier_capping',
        meta: {
            permission: "advertiser",
            authRequired: true,
        },
        component: () => import('../views/bulk-edit-delivery-modifier/index'),
    },
    {
        path: '/advertisers/:advertiser_id/campaigns/:in_campaign_ids/line-items/bulk_delivery_modifier',
        name: 'bulk_delivery_modifier',
        meta: {
            permission: "advertiser",
            authRequired: true,
        },
        component: () => import('../views/bulk-edit-delivery-modifier/index'),
    },
    {
        path: '/reports',
        name: 'report',
        meta: {
            permission: "report",
            authRequired: true,
            title: "Report"
        },
        component: () => import('../views/manage/reports/dashboard/dashboard'),
    },
    {
        path: '/report/:type',
        name: 'report_type',
        meta: {
            permission: "reports",
            authRequired: true,
            title: "Report"
        },
        component: () => import('../views/reports/default'),
    },
    {
        path: '/advertiser/:type',
        name: 'add_advertiser',
        meta: {
            permission: "advertiser",
            authRequired: true,
            title: `{type} Advertiser`
        },
        component: () => import('../views/manage/advertisers/add'),
    },
    {
        path: '/campaigns',
        name: 'campaigns',
        meta: {
            permission: "campaign",
            authRequired: true,
            title: "Campaigns"
        },
        component: () => import('../views/manage/campaigns/list'),
        beforeEnter: (to, from, next) => {
            console.log(from.query);
            if (Object.hasOwn(from.query, 'advertiser_id')) {
                localStorage.setItem('advertiser_id', from.query.advertiser_id)
            } else {
                localStorage.removeItem('advertiser_id', from.query.advertiser_id)
            }
            next()
        },
    },
    {
        path: '/campaign-report/:id/:campaign_id',
        name: 'campaign_report',
        meta: {
            permission: "campaign",
            authRequired: true,
            title: "Campaign Reports"
        },
        component: () => import('../views/manage/campaigns/report'),
    },
    {
        path: '/campaign/:type',
        name: 'add_campaigns',
        meta: {
            permission: "campaign",
            authRequired: true,
            title: `{type} Campaign`
        },
        component: () => import('../views/manage/campaigns/add'),
    },
    {
        path: '/line-items',
        name: 'line_items',
        meta: {
            permission: "line_item",
            authRequired: true,
            title: "Line Items"
        },
        component: () => import('../views/manage/line_items/list'),
        beforeEnter: (to, from, next) => {
            console.log("Routes", from)
            if (Object.hasOwn(from.query, 'advertiser_id')) {
                localStorage.setItem('advertiser_id', from.query.advertiser_id)
            } else {
                localStorage.removeItem('advertiser_id', from.query.advertiser_id)
            }
            next()
        },
    },
    {
        path: '/line-item/:type',
        name: 'add_line_items',
        meta: {
            permission: "line_item",
            authRequired: true,
            title: `{type} Line item`
        },
        component: () => import('../views/manage/line_items/add'),
    },
    {
        path: '/line-item-report/:id/:line_item_id',
        name: 'line_items_report',
        meta: {
            name: 'line_items_report',
            permission: "line_item",
            authRequired: true,
        },
        component: () => import('../views/manage/line_items/report'),
    },
    {
        path: '/creatives/bulk-edit-weights',
        name: 'line_items_bulk_edit_weights',
        meta: {
            permission: "creative",
            authRequired: true,
        },
        component: () => import('../views/manage/creatives/bulk_edit_weight'),
    },
    {
        path: '/creatives',
        name: 'creative',
        meta: {
            permission: "creative",
            authRequired: true,
            title: "Creatives"
        },
        component: () => import('../views/manage/creatives/list'),
        beforeEnter: (to, from, next) => {
            if (Object.hasOwn(from.query, 'advertiser_id')) {
                localStorage.setItem('advertiser_id', from.query.advertiser_id)
            } else {
                localStorage.removeItem('advertiser_id', from.query.advertiser_id)
            }
            next()
        },
    },
    {
        path: '/creative/:type',
        name: 'add_creative',
        meta: {
            permission: "creative",
            authRequired: true,
            title: "{type} Creative"
        },
        component: () => import('../views/manage/creatives/add'),
    },
    {
        path: '/creative-add-ons',
        name: 'creative Add-ons',
        meta: {
            permission: "creative_addon",
            authRequired: true,
            title: "Creative Add On"
        },
        component: () => import('../views/assets/creative-ad-ons/list.vue'),
    },
    {
        path: '/creative-add-on/:type',
        name: 'add-creative-add-ons',
        meta: {
            permission: "creative_addon",
            authRequired: true,
            title: "{type} Creative Add On"
        },
        component: () => import('../views/assets/creative-ad-ons/add'),
    },
    {
        path: '/custom-lists',
        name: 'lists',
        meta: {
            permission: "custom_list",
            authRequired: true,
            title: "Custom List"
        },
        component: () => import('../views/assets/custom-lists/list.vue'),
    },
    {
        path: '/custom-lists/:type',
        name: 'add-lists',
        meta: {
            permission: "custom_list",
            title: "{type} Custom List",
            authRequired: true,
        },
        component: () => import('../views/assets/custom-lists/add'),
    },
    {
        path: '/segment-group',
        name: 'segment',
        meta: {
            permission: "preset",
            authRequired: true,
            title: "Segments"
        },
        component: () => import('../views/assets/segment-group/list.vue'),
    },
    {
        path: '/segment-group/:type',
        name: 'add-segment',
        meta: {
            permission: "preset",
            authRequired: true,
            title: `{type} Segment`
        },
        component: () => import('../views/assets/segment-group/add'),
    },
    {
        path: '/deals',
        name: 'deal',
        meta: {
            permission: "deal",
            authRequired: true,
            title: "Deals"
        },
        component: () => import('../views/assets/deals/list.vue'),

    },
    {
        path: '/deal/:type',
        name: 'add_deal',
        meta: {
            permission: "deal",
            authRequired: true,
            title: "{type} Deal"
        },
        component: () => import('../views/assets/deals/add'),
    },
    {
        path: '/vendors',
        name: 'vendor',
        meta: {
            permission: "vendor",
            authRequired: true,
            title: "Vendors"
        },
        component: () => import('../views/admin/vendors/list'),
    },
    {
        path: '/vendor/:type',
        name: 'add_vendor',
        meta: {
            permission: "vendor",
            authRequired: true,
            title: "{type} Vendor"
        },
        component: () => import('../views/admin/vendors/add'),
    },
    {
        path: '/users',
        name: 'user',
        meta: {
            permission: "user",
            authRequired: true,
            title: "Users"
        },
        component: () => import('../views/admin/users/list'),
    },
    {
        path: '/user-roles',
        name: 'user_roles',
        meta: {
            permission: "role",
            authRequired: true,
            title: "User Roles"
        },
        component: () => import('../views/admin/user-roles/list'),
    },
    {
        path: '/user-role/:type',
        name: 'add_user_roles',
        meta: {
            permission: "role",
            authRequired: true,
            title: "{type} User Role"
        },
        component: () => import('../views/admin/user-roles/add'),
    },
    {
        path: '/user/:type',
        name: 'add_user',
        meta: {
            permission: "user",
            authRequired: true,
            title: "{type} Users"
        },
        component: () => import('../views/admin/users/add'),
    },
    {
        path: '/accounts',
        name: 'account',
        meta: {
            permission: "account",
            authRequired: true,
            title: "Accounts"
        },
        beforeEnter: (to, from, next) => {
            const Obj = JSON.parse(localStorage.getItem('user'))
            console.log("this is user", Obj.user)
            if (Obj.user.super_user === true) {
                next()
            } else {
                next('/advertisers')
            }

        },
        component: () => import('../views/admin/accounts/list'),
    },
    {
        path: '/no-permission',
        name: 'no-permission',
        component: () => import('../views/utility/no-permission'),
    },
    {
        path: '/account/:type',
        name: 'add_account',
        meta: {
            permission: "account",
            authRequired: true,
            title: "{type} Account"
        },

        component: () => import('../views/admin/accounts/add'),
    },
    {
        path: '/update-password',
        name: 'update_password',
        meta: {
            permission: "users/profile",
            authRequired: true,
            title: "Change Password",
        },
        component: () => import('../views/account/update-password'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/account/login'),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'advertiser'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'default'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot password',
        component: () => import('../views/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'default'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    }, {
        path: '/recoverpwd',
        name: 'Recovery Password',
        component: () => import('../views/account/recover-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'default'})
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            
            authRequired: true
        },
    },
    {
        path: '/404',
        name: '404',
        component: require('../views/utility/404').default,
    },
    // new ui routes
    {
        path: "/",
        name: "default",
        meta: {
            permission: "dashboard",
            authRequired: true,
            title: "Manage Dashboard"
        },
        component: () => import("../views/dashboards/default"),
      },
      {
        path: "/dashboard/:type/:hash?",
        name: "Dashboard",
         meta: {
            permission: "dashboard",
            authRequired: true,
            title: "Customize Dashboard"
        },
        component: () => import("../views/dashboards/add-dashboard"),
      },
      {
        path: "/overview/:hash",
        name: "view-dashboard",
         meta: {
            permission: "dashboard",
            authRequired: true,
            title: "View Dashboard"
        },
        component: () => import("../views/dashboards/view-dashboard"),
      },
      {
        path: "/forecast/:type",
        name: "add-forecast",
         meta: {
            permission: "forecast",
            authRequired: true,
            title: "{type} Forecast"
        },
        component: () => import("../views/planning/forecast/add"),
      },
      {
        path: "/forecast",
        name: "forecast",
        meta: {
            permission: "forecast",
            authRequired: true,
            title: "Forecast"
        },
        component: () => import("../views/planning/forecast/list"),
      },
      {
        path: "/forecast/report",
        name: "forecast-report",
        meta: {
            permission: "forecast",
            authRequired: true,
            title: "Forecast Report"
        },
        component: () => import("../views/planning/forecast/report"),
      },
      {
        path: "/groups",
        name: "groups",
         meta: {
            permission: "group",
            authRequired: true,
            title: "Groups"
        },
        component: () => import("../views/planning/groups/list"),
      },
      {
        path: "/retargeting-segments/:segment?",
        name: "retargeting-segments-list",
         meta: {
            permission: "retargeting_segments",
            authRequired: true,
            title: "Retargeting Segments"
        },
        component: () => import("../views/assets/retargeting-segments/list"),
      },
      {
        path: "/proposals",
        name: "proposals",
         meta: {
            permission: "proposal",
            authRequired: true,
            title: "Proposals"
        },
        component: () => import("../views/planning/proposals/list"),
      },
      {
        path: "/analytics/reports",
        name: "reports",
         meta: {
            permission: "advance_reports",
            authRequired: true,
            title: "Report Dashboard"
        },
        component: () => import("../views/analytics/reports/default"),
      },
      {
        path: "/analytics/report/:type/:id?",
        name: "add-reports",
         meta: {
            permission: "advance_reports",
            authRequired: true,
            title: "{type} Report"
        },
        component: () => import("../views/analytics/reports/add-report"),
      },
      {
        path: "/analytics/attribution/:type",
        name: "attribution",
        meta: {
            //permission: "*_attributions", //let the util handle this
            authRequired: true,
            title: `{type} Attributions`,
        },
        component: () => import("../views/analytics/attributions/type/list"),
      },
      {
        path: "/analytics/attribution/sales-attribution-report",
        name: "sales-attribution-report",
         meta: {
            //permission: "user", //let the util handle this
            authRequired: true,
            title: `Sales Attribution Report`,
        },
        component: () => import("../views/analytics/attributions/type/sales-attribution-report"),
      },
      {
        path: "/analytics/attribution/web-attribution-report",
        name: "web-attribution-report",
         meta: {
            //permission: "user", //let the util handle this
            authRequired: true,
            title: `Web Attribution Report`, 
        },
        component: () => import("../views/analytics/attributions/type/web-attribution-report"),
      },
      {
        path: "/analytics/attribution/location-attribution-report",
        name: "location-attribution-report",
         meta: {
            //permission: "user", //let the util handle this
            authRequired: true,
            title: `Location Attribution Report`,
        },
        component: () => import("../views/analytics/attributions/type/location-attribution-report"),
      },
      {
        path: "/analytics/attribution/:type/:action",
        name: "action-attribution",
         meta: {
            //permission: "*_attributions", //let the util handle this
            authRequired: true,
            title: `{action} {type} Attributions`,
        },
        component: () => import("../views/analytics/attributions/type/add"),
      },
      {
        path: "/providers-tool",
        name: "providers",
         meta: {
            permission: "provider",
            authRequired: true,
            title: `{action} {type} Attributions`,
        },
        component: () => import("../views/assets/providers/list"),
      },
      {
        path: "/pixels",
        name: "pixels",
         meta: {
            permission: "web_pixels",
            authRequired: true,
            title: `Pixel Attributions`,
        },
        component: () => import("../views/assets/pixels/list"),
      },
      {
        path: "/pixels/attribution-pixel-report",
        name: "attribution-pixel-report",
         meta: {
            permission: "web_pixels",
            authRequired: true,
            title: `Attribution Pixel Report`,
        },
        component: () => import("../views/assets/pixels/attribution-pixel-report"),
      },
    //   {
    //     path: "/analytics/attribution/location",
    //     name: "location_attribution",
    //      meta: {
    //         permission: "user",
    //         authRequired: true,
    //     },
    //     component: () => import("../views/analytics/attributions/location/list"),
    //   },
    //   {
    //     path: "/analytics/attribution/sales",
    //     name: "sales_attribution",
    //      meta: {
    //         permission: "user",
    //         authRequired: true,
    //     },
    //     component: () => import("../views/analytics/attributions/sales/list"),
    //   },

    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    // {
    //     path: '*',
    //     redirect: '404',
    // },

]
