/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getManufacturers,
    postManufacturerApi,
    deleteManufacturerApi,
    manufacturersExists,
    manufacturersDomainExists,
    getSingleManufacturer,
    putManufacturerApi,
    getManufacturersRecord,
} from "../../helpers/api";

export const state = {
    manufacturers: [],
    manufacturersListData: [],
    manufacturerSucccess: null,
    manufacturerSucccessWithoutRefresh: null,
    manufacturerSingleData: {},
    manufacturerExists: null,
    manufacturerDomainExists: null,
    manufacturerErr: null,
    manufacturerDeleteStatus: {},
    manufacturersDownload: null,
};
export const getters = {
    manufacturers: state => state.manufacturers,
    manufacturersListData: state => state.manufacturersListData,
    manufacturerSucccess: state => state.manufacturerSucccess,
    manufacturerSucccessWithoutRefresh: state => state.manufacturerSucccessWithoutRefresh,
    manufacturerSingleData: state => state.manufacturerSingleData,
    manufacturerExists: state => state.manufacturerExists,
    manufacturerDomainExists: state => state.manufacturerDomainExists,
    manufacturerErr: state => state.manufacturerErr,
    manufacturerDeleteStatus: state => state.manufacturerDeleteStatus,
    manufacturersDownload: state => state.manufacturersDownload,
};

export const mutations = {
    setManufacturers(state, newValue) {
        state.manufacturers = newValue
    },
    setExportManufacturerRecord(state, newValue) {
        state.manufacturersDownload = newValue
    },
    setManufacturersList(state, newValue) {
        state.manufacturersListData = newValue
    },
    setManufacturerSuccess(state, newValue) {
        state.manufacturerSucccess = newValue
    },
    setManufacturerSuccessWithoutRefresh(state, newValue) {
        state.manufacturerSucccessWithoutRefresh = newValue
    },
    setManufacturerExist(state, newValue) {
        state.manufacturerExists = newValue
    },
    setManufacturerDomainExist(state, newValue) {
        state.manufacturerDomainExists = newValue
    },
    setSingleManufacturers(state, newValue) {
        state.manufacturerSingleData = newValue
    },
    setManufacturerErr(state, newValue) {
        state.manufacturerErr = newValue
    },
    setManufacturerDeleteStatus(state, newValue) {
        state.manufacturerDeleteStatus = newValue
    }
};

export const actions = {
    fetchManufacturers({commit}, payload) {
        let isPayload = payload.action ? payload.action : "setManufacturers";
        if (payload.action) {
            delete payload.action;
        }
        getManufacturers(payload).then(res => {
            commit(isPayload, res.data)
        })
    },
    exportManufacturerRecord({commit}, payload) {
        getManufacturersRecord(payload).then(res => {
            commit('setExportManufacturerRecord', res.data)
        })
    },
    fetchSingleManufacturers({commit}, id) {
        getSingleManufacturer(id).then(res => {
            commit('setSingleManufacturers', res.data)
        })
    },
    isManufacturersExists({commit}, payload) {
        manufacturersExists(payload).then(res => {
            commit('setManufacturerExist', res.data)
        }, () => {
            commit('setManufacturerExist', true)
        })
    },
    isManufacturersDomainExists({commit}, payload) {
        manufacturersDomainExists(payload).then(res => {
            commit('setManufacturerDomainExist', res.data)
        }, () => {
            commit('setManufacturerDomainExist', true)
        })
    },
    postManufacturer({commit}, payload) {
        postManufacturerApi(payload).then(res => {
            commit('setManufacturerSuccess', res.data)
        }, (error) => {
            commit('setManufacturerErr', error.data)
        })
    },
    putManufacturer({commit}, payload) {
        putManufacturerApi(payload).then(res => {
            (Object.hasOwn(payload, 'diff_state') ? commit('setManufacturerSuccessWithoutRefresh', res.data) : commit('setManufacturerSuccess', res.data));
        }, (error) => {
            commit('setManufacturerErr', error.data)
        })
    },
    deleteManufacturer({commit}, payload) {
        deleteManufacturerApi(payload).then(res => {
            commit('setManufacturerDeleteStatus', res.data)
        }, (error) => {
            commit('setManufacturerDeleteStatus', error.data)
        })
    },
    clearDeleteManufacturer({commit}) {
        commit('setManufacturerDeleteStatus', null)
    },
    clearManufacturer({commit}) {
        commit('setManufacturerSuccessWithoutRefresh', null);
        commit('setManufacturerSuccess', null);
        commit('setManufacturerErr', null);
        commit('setManufacturers', null);
        commit('setManufacturersList', null);
        commit('setExportManufacturerRecord', null);
    },
    clearIsManufacturerExist({commit}) {
        commit('setManufacturerExist', null);
    },
    clearIsManufacturerDomainExist({commit}) {
        commit('setManufacturerDomainExist', null);
    }
};
