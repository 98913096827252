/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getDealApi,
    deleteDealApi,
    postDealApi,
    getSingleDeal,
    putDealApi,
    dealExistAPi,
    getDealsRecordApi
} from "../../helpers/api";

export const state = {
    deals: [],
    dealSuccess: {},
    dealsDeleteStatus: {},
    dealErr: {},
    dealExists: null,
    dealSingleData: {},
    dealPutSuccess: {},
    dealsDownload: null
};

export const getters = {
    deals: state => state.deals,
    dealSuccess: state => state.dealSuccess,
    dealsDeleteStatus: state => state.dealsDeleteStatus,
    dealErr: state => state.dealErr,
    dealExists: state => state.dealExists,
    dealSingleData: state => state.dealSingleData,
    dealPutSuccess: state => state.dealPutSuccess,
    dealsDownload: state => state.dealsDownload,
};

export const mutations = {
    setDeals(state, newValue) {
        state.deals = newValue
    },
    setDealDeleteStatus(state, newValue) {
        state.dealsDeleteStatus = newValue
    },
    setDealErr(state, newValue) {
        state.dealErr = newValue
    },
    setDealExist(state, newValue) {
        console.log(newValue)
        state.dealExists = newValue
    },
    setDealSuccess(state, newValue) {
        state.dealSuccess = newValue
    },
    setSingleDeal(state, newValue) {
        state.dealSingleData = newValue
    },
    setDealPutSuccess(state, newValue) {
        state.dealPutSuccess = newValue
    },
    setExportDealsRecord(state, newValue) {
        state.dealsDownload = newValue
    },
};

export const actions = {
    fetchDeals({commit}, payload) {
        getDealApi(payload).then(res => {
            commit('setDeals', res.data)
        })
    },
    exportDealsRecord({commit}, payload) {
        getDealsRecordApi(payload).then(res => {
            commit('setExportDealsRecord', res.data)
        })
    },
    deleteDeal({commit}, payload) {
        deleteDealApi(payload).then(res => {
            commit('setDealDeleteStatus', res.data)
        }, (error) => {
            commit('setDealDeleteStatus', error.data)
        })
    },
    clearDeal({commit}) {
        commit('setDealSuccess', null);
        commit('setDealErr', null);
        commit('setDealPutSuccess', null);
        commit('setExportDealsRecord', null);
    },
    clearIsDealExist({commit}) {
        commit('setDealExist', null);
    },
    postDeal({commit}, payload) {
        postDealApi(payload).then(res => {
            commit('setDealSuccess', res.data)
        }, (error) => {
            commit('setDealErr', error.data)
        })
    },
    isDealExist({commit}, payload) {
        dealExistAPi(payload).then(res => {
            commit('setDealExist', res.data)
        }, (error) => {
            commit('setDealExist', error.data)
        })
    },
    fetchSingleDeal({commit}, payload) {
        getSingleDeal(payload).then(res => {
            commit('setSingleDeal', res.data)
        })
    },
    putDeal({commit}, payload) {
        putDealApi(payload).then(res => {
            commit('setDealPutSuccess', res.data)
        }, (error) => {
            commit('setDealErr', error.data)
        })
    },
};
