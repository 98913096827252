/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getAccountsApi, postAccountApi, postFullAccountApi,
    putAccountApi, putFullAccountApi,
    deleteAccountApi, getSingleAccountApi, getFullSingleAccountApi,
} from "../../helpers/api";

export const state = {
    accounts: [],
    accountErr: {},
    accountSuccess: {},
    accountSingleData: {},
    accountDeleteStatus: {},
};
export const getters = {
    accounts: state => state.accounts,
    accountErr: state => state.accountErr,
    accountSuccess: state => state.accountSuccess,
    accountSingleData: state => state.accountSingleData,
    accountDeleteStatus: state => state.accountDeleteStatus,
};

export const mutations = {
    setAccounts(state, newValue) {
        state.accounts = newValue
    },
    setAccountErr(state, newValue) {
        state.accountErr = newValue
    },
    setAccountSuccess(state, newValue) {
        state.accountSuccess = newValue
    },
    setAccountDeleteStatus(state, newValue) {
        state.accountDeleteStatus = newValue
    },
    setSingleAccount(state, newValue) {
        state.accountSingleData = newValue
    },

};

export const actions = {
    fetchAccounts({commit}, payload) {
        getAccountsApi(payload).then(res => {
            if(res && res.data) {
                commit('setAccounts', res.data)
            }
        })
    },
    putAccount({commit}, payload) {
        putAccountApi(payload).then(res => {
            commit('setAccountSuccess', res.data)
        }, (error) => {
            commit('setAccountErr', error.data)
        })
    },
    putFullAccount({commit}, payload) {
        putFullAccountApi(payload).then(res => {
            commit('setAccountSuccess', res.data)
        }, (error) => {
            commit('setAccountErr', error.data)
        })
    },
    postAccount({commit}, payload) {
        postAccountApi(payload).then(res => {
            commit('setAccountSuccess', res.data)
        }, (error) => {
            commit('setAccountErr', error.data)
        })
    },
    postFullAccount({commit}, payload) {
        postFullAccountApi(payload).then(res => {
            commit('setAccountSuccess', res.data)
        }, (error) => {
            commit('setAccountErr', error.data)
        })
    },
    deleteAccount({commit}, payload) {
        deleteAccountApi(payload).then(res => {
            commit('setAccountDeleteStatus', res.data)
        }, (error) => {
            commit('setAccountDeleteStatus', error.data)
        })
    },
    fetchSingleAccount({commit}, id) {
        getSingleAccountApi(id).then(res => {
            commit('setSingleAccount', res.data)
        })
    },
    fetchFullSingleAccount({commit}, id) {
        getFullSingleAccountApi(id).then(res => {
            commit('setSingleAccount', res.data)
        })
    },
    clearAccount({commit}) {
        commit('setAccounts', null);
        commit('setAccountSuccess', null);
    },
    clearDeleteAccount({commit}) {
        commit('setAccountDeleteStatus', null)
    },
};
