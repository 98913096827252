/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getCampaigns,
    postCampaignApi,
    postCampaignFindAndReplaceApi,
    postImportCampaignLineItemApi,
    getVendorFeesApi,
    deleteCampaignApi,
    deleteBulkCampaignApi,
    postCampaignMultipleVendorFeeApi,
    postCampaignVendorFreeApi,
    getCampaignSingleDataApi,
    putCampaignApi,
    putCampaignStrictApi,
    getCampaignVendorFeesApi,
    putCampaignVendorFeesApi,
    deleteCampaignVendorFeesApi,
    campaignExistApi,
    getCampaignsRecord,
    getCampaignsSummary,
    getBudgetFlightsRecord,
    duplicateCampaignApi, 
    getCampaignBudgetFlightApi,
    deleteCampaignBudgetFlightApi,
} from "../../helpers/api";

export const state = {
    campaigns: [],
    campaignSuccess: {},
    campaignSuccessWithoutRefresh: {},
    campaignFindAndReplace: {},
    campaignErr: {},
    campaignFindAndReplaceErr: {},
    importCampaignLineItemSuccess: {},
    importCampaignLineItemErr: {},
    vendorFeesSuccess: [],
    campaignDeleteStatus: {},
    campaignBulkDeleteStatus: {},
    vendorCreateFeesSuccess: {},
    campaignSingleData: {},
    campiagnVendorFeesSuccess: [],
    campiagnBudgetFlightSuccess: [],
    campaignsSummary: [],
    campaignExist: null,
    campaignsDownload: null,
    budgetFlightsDownload: null,
    campaignFlightDeleteStatus: {},
    flightLogsData:null,
};
export const getters = {
    campaigns: state => state.campaigns,
    campaignSuccess: state => state.campaignSuccess,
    campaignSuccessWithoutRefresh: state => state.campaignSuccessWithoutRefresh,
    campaignFindAndReplace: state => state.campaignFindAndReplace,
    campaignFindAndReplaceErr: state => state.campaignFindAndReplaceErr,
    importCampaignLineItemSuccess: state => state.importCampaignLineItemSuccess,
    importCampaignLineItemErr: state => state.importCampaignLineItemErr,
    campaignErr: state => state.campaignErr,
    vendorFeesSuccess: state => state.vendorFeesSuccess,
    campiagnVendorFeesSuccess: (state) => state.campiagnVendorFeesSuccess,
    campiagnBudgetFlightSuccess: (state) => state.campiagnBudgetFlightSuccess,
    campaignDeleteStatus: state => state.campaignDeleteStatus,
    campaignBulkDeleteStatus: state => state.campaignBulkDeleteStatus,
    vendorCreateFeesSuccess: (state) => state.vendorCreateFeesSuccess,
    campaignSingleData: state => state.campaignSingleData,
    campaignExist: state => state.campaignExist,
    campaignsDownload: state => state.campaignsDownload,
    budgetFlightsDownload: state => state.budgetFlightsDownload,
    campaignsSummary: state => state.campaignsSummary,
    campaignFlightDeleteStatus: state => state.campaignFlightDeleteStatus,
    flightLogsData: state => state.flightLogsData
};

export const mutations = {
    setCampaigns(state, newValue) {
        state.campaigns = newValue
    },
    setCampaignsSummary(state, newValue) {
        state.campaignsSummary = newValue
    },
    setCampaignErr(state, newValue) {
        state.campaignErr = newValue
    },
    setCampaignFindAndReplaceErr(state, newValue) {
        state.campaignFindAndReplaceErr = newValue
    },
    setCampaignSuccess(state, newValue) {
        state.campaignSuccess = newValue
    },
    setCampaignSuccessWithoutRefresh(state, newValue) {
        state.campaignSuccessWithoutRefresh = newValue
    },
    setCampaignFindAndReplace(state, newValue) {
        state.campaignFindAndReplace = newValue
    },
    setImportCampaignLineItemSuccess(state, newValue) {
        state.importCampaignLineItemSuccess = newValue
    },
    setImportCampaignLineItemErr(state, newValue) {
        state.importCampaignLineItemErr = newValue
    },
    setCampaignDeleteStatus(state, newValue) {
        state.campaignDeleteStatus = newValue
    },
    setBulkCampaignDeleteStatus(state, newValue) {
        state.campaignBulkDeleteStatus = newValue
    },
    setVendorFeesSuccess(state, newValue) {
        state.vendorFeesSuccess = newValue
    },
    setCampaignVendorFeesSuccess(state, newValue) {
        state.campiagnVendorFeesSuccess = newValue
    },
    setCampaignBudgetFlightSuccess(state, newValue) {
        state.campiagnBudgetFlightSuccess = newValue
    },
    setVendorCreateFeesSuccess(state, newValue) {
        state.vendorCreateFeesSuccess = newValue
    },
    setCampaignSingleDataSuccess(state, newValue) {
        state.campaignSingleData = newValue
    },
    setCampaignExist(state, newValue) {
        state.campaignExist = newValue
    },
    setExportCampaignRecord(state, newValue) {
        state.campaignsDownload = newValue
    },
    setExportBudgetFlightsRecord(state, newValue) {
        console.log('budgetFlightsDownload newValue',newValue)
        state.budgetFlightsDownload = newValue
    },
    setCampaignFlightDeleteStatus(state, newValue) {
        state.campaignFlightDeleteStatus = newValue
    },
    setFlightActivityLog(state, newValue) {
        console.log('flightLogsData',newValue)
        state.flightLogsData = newValue
    },
};

export const actions = {
    fetchCampaigns({commit}, payload) {
        getCampaigns(payload).then(res => {
            let result = res.data;
            if (payload.version) {
                result.version = payload.version;
            }
            commit('setCampaigns', result)
        })
    },
    fetchCampaignSummary({commit}, payload) {
        getCampaignsSummary(payload).then(res => {
            commit('setCampaignsSummary', res.data)
        })
    },
    postCampaign({commit}, payload) {
        postCampaignApi(payload).then(res => {
            commit('setCampaignSuccess', res.data)
        }, (error) => {
            commit('setCampaignErr', error.data)
        })
    },
    exportCampaignRecord({commit}, payload) {
        getCampaignsRecord(payload).then(res => {
            commit('setExportCampaignRecord', res.data)
        })
    },
    exportBudgetFlightsRecord({commit}, payload) {
        getBudgetFlightsRecord(payload).then(res => {
            commit('setExportBudgetFlightsRecord', res.data)
        })
    },
    duplicateCampaign({commit}, payload) {
        duplicateCampaignApi(payload).then(res => {
            commit('setCampaignSuccess', res.data)
        }, (error) => {
            commit('setCampaignErr', error.data)
        })
    },
    postCampaignFindAndReplace({commit}, payload) {
        postCampaignFindAndReplaceApi(payload).then(res => {
            commit('setCampaignFindAndReplace', res.data)
        }, (error) => {
            commit('setCampaignFindAndReplaceErr', error.data)
        })
    },
    postImportCampaignLineItem({commit}, payload) {
        postImportCampaignLineItemApi(payload).then(res => {
            commit('setImportCampaignLineItemSuccess', res.data)
        }, (error) => {
            commit('setImportCampaignLineItemErr', error.data)
        })
    },
    putCampaign({commit}, payload) {
        putCampaignApi(payload).then(res => {
            (Object.hasOwn(payload, 'diff_state') ? commit('setCampaignSuccessWithoutRefresh', res.data) : commit('setCampaignSuccess', res.data));
        }, (error) => {
            commit('setCampaignErr', error.data)
        })
    },
    putStrictCampaign({commit}, payload) {
        putCampaignStrictApi(payload).then(res => {
            (Object.hasOwn(payload, 'diff_state') ? commit('setCampaignSuccessWithoutRefresh', res.data) : commit('setCampaignSuccess', res.data));
        }, (error) => {
            commit('setCampaignErr', error.data)
        })
    },
    deleteCampaign({commit}, payload) {
        deleteCampaignApi(payload).then(res => {
            commit('setCampaignDeleteStatus', res.data)
        }, (error) => {
            commit('setCampaignDeleteStatus', error.data)
        })
    },
    deleteBulkCampaign({commit}, payload) {
        deleteBulkCampaignApi(payload).then(res => {
            commit('setBulkCampaignDeleteStatus', res.data)
        }, (error) => {
            commit('setBulkCampaignDeleteStatus', error.data)
        })
    },
    clearDeleteCampaign({commit}) {
        commit('setCampaignDeleteStatus', null)
        commit('setBulkCampaignDeleteStatus', null)
        commit('setCampaignFlightDeleteStatus',null)
    },
    fetchVendorFees({commit}) {
        getVendorFeesApi().then(res => {
            commit('setVendorFeesSuccess', res.data)
        })
    },
    fetchCampaignVendorFees({commit}, payload) {
        getCampaignVendorFeesApi(payload).then(res => {
            commit('setCampaignVendorFeesSuccess', res.data)
        })
    },
    fetchCampaignBudgetFlight({commit}, payload) {
        getCampaignBudgetFlightApi(payload).then(res => {
            commit('setCampaignBudgetFlightSuccess', res.data)
        })
    },
    newCampaignMultipleVendorFees({commit}, payload) {
        postCampaignMultipleVendorFeeApi(payload).then(res => {
            commit('setVendorCreateFeesSuccess', res.data)
        })
    },
    newCampaignVendorFees({commit}, payload) {
        postCampaignVendorFreeApi(payload).then(res => {
            commit('setVendorCreateFeesSuccess', res.data)
        })
    },
    putCampaignVendorFees({commit}, payload) {
        putCampaignVendorFeesApi(payload).then(res => {
            commit('setVendorCreateFeesSuccess', res.data)
        })
    },
    deleteCampaignVendorFees({commit}, payload) {
        deleteCampaignVendorFeesApi(payload).then(res => {
            commit('setVendorCreateFeesSuccess', res.data)
        })
    },
    fetchSingleCampaign({commit}, payload) {
        getCampaignSingleDataApi(payload).then(res => {
            commit('setCampaignSingleDataSuccess', res.data)
        })
    },
    clearCampaign({commit}) {
        commit('setCampaignSuccessWithoutRefresh', null);
        commit('setCampaignSuccess', null);
        commit('setCampaignErr', null);
        commit('setCampaignFindAndReplaceErr', null);
        commit('setCampaignFindAndReplace', null);
        commit('setVendorFeesSuccess', null);
        commit('setExportCampaignRecord', null);
        commit('setExportBudgetFlightsRecord', null)
        commit('setCampaignsSummary', null);
    },
    clearVendorFees({commit}) {
        commit('setCampaignVendorFeesSuccess', null);
    },
    clearImportCampaignLineItem({commit}) {
        commit('setImportCampaignLineItemErr', null);
        commit('setImportCampaignLineItemSuccess', null);
    },
    clearBudgetFlight({commit}) {
        commit('setCampaignBudgetFlightSuccess', null);
    },
    clearCampaignList({commit}) {
        commit('setCampaigns', null);
    },
    clearIsCampaignExist({commit}) {
        commit('setCampaignExist', null);
    },
    isCampaignExist({commit}, payload) {
        campaignExistApi(payload).then(res => {
            commit('setCampaignExist', res.data)
        }, () => {
            commit('setCampaignExist', null)
        })
    },
    deleteCampaignBudgetFlight({commit}, payload) {
        deleteCampaignBudgetFlightApi(payload).then(res => {
            commit('setCampaignFlightDeleteStatus', res.data)
        }, (error) => {
            commit('setCampaignFlightDeleteStatus', error.data)
        })
    },

   
};
