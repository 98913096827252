/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */

import {DspAuth} from '@octillion/auth'


export async function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && (user.apiKey || user.token)) {
        if(user.token){
            const auth = DspAuth.getInstance()
            return {
                token: await auth.getToken()
            }
        }
        return {'Authorization': user.apiKey};
    } else {
        return {};
    }
}

export function getLoggedInUser() {
    // return authorization header with jwt token
    return JSON.parse(localStorage.getItem('user'));
}

export function getAccountPlatformObj(isCreated = false, isUpdated = false, onlyPlatformId = false, isUser = false, onlyAccountId = false, accountId = false) {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.user) {
        let reponseObject = {account_id: user.user.account_id, platform_id: user.user.platform_id};

        if (isCreated) {
            reponseObject = {...reponseObject, ...{created_by: user.user.user_id}};
        }
        if (isUpdated) {
            reponseObject = {...reponseObject, ...{created_by: user.user.user_id, updated_by: user.user.user_id}};
            if(!isCreated) {
                delete reponseObject.created_by;
            }
        }
        if (isUser) {
            reponseObject = {...reponseObject, ...{user_id: user.user.user_id}};
        }
        if(onlyPlatformId) {
            reponseObject = { platform_id: user.user.platform_id};
        }
        if(onlyAccountId) {
            reponseObject = { account_id: user.user.account_id};
        }
        if(accountId) {
            reponseObject = {...reponseObject, ...{ account_id: user.user.account_id}}
        }
        console.log()
        return reponseObject;
    } else {
        return {};
    }
}
