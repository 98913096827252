/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getUserApi,
    getUserWithPaginationAapi,
    deleteUserApi,
    postUserApi,
    getSingleUser,
    putUserApi,
    putUserProfileApi,
    emailExistsApi, 
    getUsersRecord,
} from "../../helpers/api";

export const state = {
    users: [],
    userSuccess: {},
    usersDeleteStatus: {},
    userErr: {},
    userSingleData: {},
    emailExists: null,
    domainNotAllow: null,
    usersDownload: null,
};

export const getters = {
    users: state => state.users,
    userSuccess: state => state.userSuccess,
    usersDeleteStatus: state => state.usersDeleteStatus,
    userErr: state => state.userErr,
    userSingleData: state => state.userSingleData,
    emailExists: state => state.emailExists,
    domainNotAllow: state => state.domainNotAllow,
    usersDownload: state => state.usersDownload
};

export const mutations = {
    setUsers(state, newValue) {
        state.users = newValue
    },
    setExportUsersRecord(state, newValue) {
        state.usersDownload = newValue
    },

    setUserDeleteStatus(state, newValue) {
        state.usersDeleteStatus = newValue
    },
    setUserErr(state, newValue) {
        state.userErr = newValue
    },
    setUserSuccess(state, newValue) {
        state.userSuccess = newValue
    },
    setSingleUser(state, newValue) {
        state.userSingleData = newValue
    },
    setEmailExist(state, newValue) {
        state.emailExists = newValue
    },
    setDomainNotAllow(state, newValue) {
        state.domainNotAllow = newValue
    },
};

export const actions = {
    fetchUsers({commit}, payload) {
        if(payload.pagination){
            getUserWithPaginationAapi(payload).then(res => {
                commit('setUsers', res.data)
            })
        }else{
            getUserApi(payload).then(res => {
                commit('setUsers', res.data)
            })
        }

    },
    deleteUser({commit}, payload) {
        deleteUserApi(payload).then(res => {
            commit('setUserDeleteStatus', res.data)
        }, (error) => {
            commit('setUserDeleteStatus', error.data)
        })
    },
    clearUser({commit}) {
        commit('setUserSuccess', null);
        commit('setUserErr', null);
        commit('setSingleUser', null);
        commit('setEmailExist', null);
        commit('setDomainNotAllow', null);

    },
    postUser({commit}, payload) {
        postUserApi(payload).then(res => {
            commit('setUserSuccess', res.data)
        }, (error) => {
            commit('setUserErr', error.data)
        })
    },
    fetchSingleUser({commit}, id) {
        getSingleUser(id).then(res => {
            commit('setSingleUser', res.data)
        })
    },
    exportUsersRecord({commit}, payload) {
        getUsersRecord(payload).then(res => {
            commit('setExportUsersRecord', res.data)
        })
    },
    putUser({commit}, payload) {
        putUserApi(payload).then(res => {
            commit('setUserSuccess', res.data)
        }, (error) => {
            commit('setUserErr', error.data)
        })
    },
    putUserProfile({commit}, payload) {
        putUserProfileApi(payload).then(res => {
            commit('setUserSuccess', res.data)
        }, (error) => {
            commit('setUserErr', error.data)
        })
    },
    isEmailExists({commit}, payload) {
        //email=warren%40octillion.tv&account_id=2
        emailExistsApi(payload).then(res => {
             commit('setEmailExist', res.data)
             commit('setDomainNotAllow',null)
        }, (error) => {
            commit('setDomainNotAllow',error.data)
            commit('setEmailExist', null)
        })
    },
    clearIsEamilExist({commit}) {
        commit('setEmailExist', null);
        commit('setDomainNotAllow', null);
        commit('setExportUsersRecord', null);
    },
    clearDeleteUser({commit}) {
        commit('setUserDeleteStatus', null);
    },
    clearUserErr({commit}) {
        commit('setUserErr', null);
    }
};
