/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getAdvertisers,
    getAdvertiserCategory,
    postAdvertiserApi,
    deleteAdvertiserApi,
    deleteFavoriteAdvertiserApi,
    advertisersExists,
    advertisersDomainExists,
    getSingleAdvertiser,
    putAdvertiserApi,
    postFavoriteAdvertiserApi,
    getAdvertisersRecord,
} from "../../helpers/api";

export const state = {
    advertisers: [],
    advertisersListData: [],
    advertiserCategories: [],
    advertiserSucccess: null,
    advertiserSucccessWithoutRefresh: null,
    advertiserSingleData: {},
    advertiserExists: null,
    advertiserDomainExists: null,
    advertiserErr: null,
    advertiserDeleteStatus: {},
    advertisersDownload: null,
};
export const getters = {
    advertisers: state => state.advertisers,
    advertisersListData: state => state.advertisersListData,
    advertiserCategories: state => state.advertiserCategories,
    advertiserSucccess: state => state.advertiserSucccess,
    advertiserSucccessWithoutRefresh: state => state.advertiserSucccessWithoutRefresh,
    advertiserSingleData: state => state.advertiserSingleData,
    advertiserExists: state => state.advertiserExists,
    advertiserDomainExists: state => state.advertiserDomainExists,
    advertiserErr: state => state.advertiserErr,
    advertiserDeleteStatus: state => state.advertiserDeleteStatus,
    advertisersDownload: state => state.advertisersDownload,
};

export const mutations = {
    setAdvertisers(state, newValue) {
        state.advertisers = newValue
    },
    setExportAdvertiserRecord(state, newValue) {
        state.advertisersDownload = newValue
    },
    setAdvertisersList(state, newValue) {
        state.advertisersListData = newValue
    },
    setAdvertiserCategories(state, newValue) {
        state.advertiserCategories = newValue
    },
    setAdvertiserSuccess(state, newValue) {
        state.advertiserSucccess = newValue
    },
    setAdvertiserSuccessWithoutRefresh(state, newValue) {
        state.advertiserSucccessWithoutRefresh = newValue
    },
    setAdvertiserExist(state, newValue) {
        state.advertiserExists = newValue
    },
    setAdvertiserDomainExist(state, newValue) {
        state.advertiserDomainExists = newValue
    },
    setSingleAdvertisers(state, newValue) {
        state.advertiserSingleData = newValue
    },
    setAdvertiserErr(state, newValue) {
        state.advertiserErr = newValue
    },
    setAdvertiserDeleteStatus(state, newValue) {
        state.advertiserDeleteStatus = newValue
    }
};

export const actions = {
    fetchAdvertisers({commit}, payload) {
        let isPayload = payload.action ? payload.action : "setAdvertisers";
        if (payload.action) {
            delete payload.action;
        }
        getAdvertisers(payload).then(res => {
            
            commit(isPayload, res && res.data ? res.data : []  )
        })
    },
    exportAdvertiserRecord({commit}, payload) {
        getAdvertisersRecord(payload).then(res => {
            commit('setExportAdvertiserRecord', res.data)
        })
    },
    fetchSingleAdvertisers({commit}, id) {
        getSingleAdvertiser(id).then(res => {
            commit('setSingleAdvertisers', res.data)
        })
    },
    isAdvertisersExists({commit}, payload) {
        advertisersExists(payload).then(res => {
            commit('setAdvertiserExist', res.data)
        }, () => {
            commit('setAdvertiserExist', true)
        })
    },
    isAdvertisersDomainExists({commit}, payload) {
        advertisersDomainExists(payload).then(res => {
            commit('setAdvertiserDomainExist', res.data)
        }, () => {
            commit('setAdvertiserDomainExist', true)
        })
    },
    fetchAdvertiserCategories({commit}) {
        getAdvertiserCategory().then(res => {
            if(res && res.data) {
                commit('setAdvertiserCategories', res.data)
            }
        })
    },
    postAdvertiser({commit}, payload) {
        postAdvertiserApi(payload).then(res => {
            commit('setAdvertiserSuccess', res.data)
        }, (error) => {
            commit('setAdvertiserErr', error.data)
        })
    },
    postFavoriteAdvertiser({commit}, payload) {
        postFavoriteAdvertiserApi(payload).then(res => {
            commit('setAdvertiserSuccess', res.data)
        }, (error) => {
            commit('setAdvertiserErr', error.data)
        })
    },
    putAdvertiser({commit}, payload) {
        putAdvertiserApi(payload).then(res => {
            // eslint-disable-next-line
            (Object.hasOwn(payload, 'diff_state') ? commit('setAdvertiserSuccessWithoutRefresh', res.data) : commit('setAdvertiserSuccess', res.data));
        }, (error) => {
            commit('setAdvertiserErr', error.data)
        })
    },
    deleteAdvertiser({commit}, payload) {
        deleteAdvertiserApi(payload).then(res => {
            commit('setAdvertiserDeleteStatus', res.data)
        }, (error) => {
            commit('setAdvertiserDeleteStatus', error.data)
        })
    },
    clearDeleteAdvertiser({commit}) {
        commit('setAdvertiserDeleteStatus', null)
    },
    deleteFavoriteAdvertiser({commit}, payload) {
        deleteFavoriteAdvertiserApi(payload).then(res => {
            commit('setAdvertiserSuccess', res.data)
        }, (error) => {
            commit('setAdvertiserErr', error.data)
        })
    },
    clearAdvertiser({commit}) {
        commit('setAdvertiserSuccessWithoutRefresh', null);
        commit('setAdvertiserSuccess', null);
        commit('setAdvertiserErr', null);
        commit('setAdvertisers', null);
        commit('setAdvertisersList', null);
        commit('setExportAdvertiserRecord', null);
    },
    clearIsAdvertiserExist({commit}) {
        commit('setAdvertiserExist', null);
    },
    clearIsAdvertiserDomainExist({commit}) {
        commit('setAdvertiserDomainExist', null);
    }

};
