<template>
 <router-view></router-view>
</template>

<script>
import appConfig from "@/app.config";
import { initializeApp } from "firebase/app";

import { notificationMethods } from "@/state/helpers";

import { getAdvertisers } from "@/helpers/api";

import { getMessaging, getToken, onMessage } from "firebase/messaging";
export default {
  name: 'App',
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    },
  },
  components: {
  },
  mounted() {
    const firebaseConfig = {
      apiKey: "AIzaSyAxQLAQJseLxBTn3tPpG78EOmh-0wWbZSg",
      authDomain: "octillion-7cae6.firebaseapp.com",
      projectId: "octillion-7cae6",
      storageBucket: "octillion-7cae6.appspot.com",
      messagingSenderId: "793409274230",
      appId: "1:793409274230:web:1d6f41a8d7705b4673ba57",
      measurementId: "G-49XF28Q20R"
    };

    const app = initializeApp(firebaseConfig);


    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // ...
    });
    

    // getToken(messaging, { vapidKey: 'BHYh34M_lfBJL31O5w-Kcypq08AuCh9DgHLfnwAG4Y_h9zuDTEYc8k8qnN5Blg4HBdrVWwbSRav2WdsebciYKx0' }).then((currentToken) => {
    //   if (currentToken) {
    //     // Send the token to your server and update the UI if necessary
    //     console.log("Token is:",currentToken);
    //     // ...
    //   } else {
    //     // Show permission request UI
    //     console.log('No registration token available. Request permission to generate one.');
    //     // ...
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    //   // ...
    // });

  },
  beforeMount() {
    let params = (new URL(document.location)).searchParams;
    let advertiser_id = params.get("advertiser_id");
    if(advertiser_id > 0) {
      getAdvertisers({advertiser_id: advertiser_id}).then(response => {
        if(response.data && response.data.results.length == 0) {
          this.$router.push({name: 'advertiser' });
        } else if(response.data.results.filter(ele => ele.advertiser_id == advertiser_id).length == 0) {
           this.$router.push({name: 'advertiser' });
        }
      })
    }
  },
  methods: {
    clearNotification: notificationMethods.clear,
  }
}
</script>
