/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import { postPresetApi, getPresetsApi, putPresetApi, getPresetsRecord, deletePresetApi, getSinglePresetApi } from "../../helpers/api";

export const state = {
    presetSuccess: null,
    presetError: null,
    presetList: [],
    segmentGroupList : [],
    presetLineItemList: [],
    presetDeliveryModiferList: [],
    presetDeliveryCappingList: [],
    presetBidModiferList:[],
    presetsDownload: [],
    presetDeleteStatus: null,
    presetSingleData: null
};

export const getters = {
    presetSuccess: state => state.presetSuccess,
    presetError: state => state.presetError,
    presetList: state => state.presetList,
    presetLineItemList: state => state.presetLineItemList,
    segmentGroupList: state => state.segmentGroupList,
    presetDeliveryModiferList: state => state.presetDeliveryModiferList,
    presetBidModiferList: state => state.presetBidModiferList,
    presetsDownload: state => state.presetsDownload,
    presetDeleteStatus: state => state.presetDeleteStatus,
    presetSingleData: state => state.presetSingleData
};

export const mutations = {
    setPresetSuccess(state, newValue) {
        state.presetSuccess = newValue
    },
    setPresetError(state, newValue) {
        state.presetError = newValue
    },
    setPresetList(state, newValue) {
        console.log('setPresetList',newValue)
        state.presetList = newValue
    },
    setPresetLineItemList(state, newValue) {
        state.presetLineItemList = newValue
    },
    setSegmentGroupList(state, newValue) {
        console.log('setSegmentGroupList','newValue',newValue)
        state.segmentGroupList = newValue
    },
    setPresetDeliveryModifierList(state, newValue) {
        state.presetDeliveryModiferList = newValue
    },
    setPresetDeliveryCappingList(state, newValue) {
        state.presetDeliveryCappingList = newValue
    },
    setPresetBidModifierList(state, newValue) {
        state.presetBidModiferList = newValue
    },
    setExportPresetsRecord(state, newValue) {
        state.presetsDownload = newValue
    },
    setPresetDeleteStatus(state, newValue) {
        state.presetDeleteStatus = newValue
    },
    setSinglePreset(state, newValue) {
        state.presetSingleData = newValue
    },
};

export const actions = {
    postPreset({commit}, payload) {
        postPresetApi(payload).then(res => {
            commit('setPresetSuccess', res.data)
        }, (error) => {
            commit('setPresetError', error)
        })
    },
    putPreset({commit}, payload) {
        putPresetApi(payload).then(res => {
            commit('setPresetSuccess', res.data)
        }, (error) => {
            commit('setPresetError', error)
        })
    },
    getPresets({commit}, payload = {}) {
       
        getPresetsApi(payload).then(res => {
           
            if(payload.object_type == 'delivery_modifier') {
                commit('setPresetDeliveryModifierList', res.data)
            }
            else if(payload.object_type == 'delivery_capping') {
                commit('setPresetDeliveryCappingList', res.data)
            }
            else if(payload.object_type == 'bid_modifier') {
                commit('setPresetBidModifierList', res.data)
            }
             else if(payload.object_type == 'line_item') {
                commit('setPresetLineItemList', res.data)
            } 
            else if(payload.object_type == 'segment') {
                commit('setSegmentGroupList', res.data)
            } else {
                commit('setPresetList', res.data)
            }
        }, () => {
            commit('setPresetList', []);
            commit('setSegmentGroupList', []);
            commit('setPresetLineItemList', []);
            commit('setPresetDeliveryModifierList', []);
            commit('setPresetDeliveryCappingList', []);
        })
    },
    getSinglePreset({commit}, payload = {}) {
        getSinglePresetApi(payload).then(res => {
            commit('setSinglePreset', res.data)
        }, (error) => {
            commit('setPresetError', error);
        })
    },
    exportPresetsRecord({commit}, payload) {
        getPresetsRecord(payload).then(res => {
            commit('setExportPresetsRecord', res.data)
        })
    },
    deletePreset({commit}, payload) {
        deletePresetApi(payload).then(res => {
            commit('setPresetDeleteStatus', res.data)
        }, (error) => {
            commit('setPresetDeleteStatus', error.data)
        })
    },
    clearDeletePreset({commit}) {
        commit('setPresetDeleteStatus', null)
    },
    clearPutPreset({commit}) {
        commit('setPresetSuccess', null);   
    },
    clearSegmentGroupPreset({commit}) {
        commit('setSegmentGroupList', []);  
    },
    clearPreset({commit}) {
        commit('setPresetSuccess', null);
        commit('setPresetError', null);
        commit('setPresetList', []);
        commit('setPresetLineItemList', []);
        commit('setPresetDeliveryModifierList', []);
        commit('setPresetDeliveryCappingList', []);
    },
};
