/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {getListApi, deleteListApi, postListApi, getSingleList, putListApi} from "../../helpers/api";

export const state = {
    lists: [],
    listSuccess: {},
    listsDeleteStatus: {},
    listErr: {},
    listExists: null,
    listSingleData: {},
};

export const getters = {
    lists: state => state.lists,
    listSuccess: state => state.listSuccess,
    listsDeleteStatus: state => state.listsDeleteStatus,
    listErr: state => state.listErr,
    listExists: state => state.listExists,
    listSingleData: state => state.listSingleData,
};

export const mutations = {
    setLists(state, newValue) {
        state.lists = newValue
    },
    setListDeleteStatus(state, newValue) {
        state.listsDeleteStatus = newValue
    },
    setListErr(state, newValue) {
        state.listErr = newValue
    },
    setListExist(state, newValue) {
        state.listExists = newValue
    },
    setListSuccess(state, newValue) {
        state.listSuccess = newValue
    },
    setSingleList(state, newValue) {
        state.listSingleData = newValue
    },
};

export const actions = {
    fetchLists({commit}, payload) {
        getListApi(payload).then(res => {
            commit('setLists', res.data)
        })
    },
    deleteList({commit}, payload) {
        deleteListApi(payload).then(res => {
            commit('setListDeleteStatus', res.data)
        }, (error) => {
            commit('setListDeleteStatus', error.data)
        })
    },
    clearList({commit}) {
        commit('setListSuccess', null);
        commit('setListErr', null);
        commit('setSingleList', null);
        commit('setListDeleteStatus', null);
    },
    postList({commit}, payload) {
        postListApi(payload).then(res => {
            commit('setListSuccess', res.data)
        }, (error) => {
            commit('setListErr', error.data)
        })
    },
    fetchSingleList({commit}, id) {
        getSingleList(id).then(res => {
            commit('setSingleList', res.data)
        })
    },
    putList({commit}, payload) {
        putListApi(payload).then(res => {
            commit('setListSuccess', res.data)
        }, (error) => {
            commit('setListErr', error.data)
        })
    },
};
