/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getObjectSearch,
} from "../../helpers/api";

export const state = {
    type: null,
    message: null,
    searchResult: []
};

export const getters = {
    searchResult: state => state.searchResult,
};


export const mutations = {
    success(state, message) {
        state.type = 'alert-success';
        state.message = message;
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;
    },
    clear(state) {
        state.type = null;
        state.message = null;
    },
    setSearchResult(state, newValue) {
        state.searchResult = newValue
    },
    
};

export const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    },
    fetchSearchResult({commit}, payload) {
        getObjectSearch(payload).then(res => {
            commit('setSearchResult', res.data)
        })
    },
    clearSearchResult({commit}) {
        commit('setSearchResult', [])
    }
};
