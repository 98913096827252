/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getParentCompanies,
    postParentCompanyApi,
    deleteParentCompanyApi,
    parentCompanyIsExists,
    // parentCompanysDomainExists,
    getSingleParentCompany,
    putParentCompanyApi,
    getParentCompaniesRecord,
} from "../../helpers/api";

export const state = {
    parentCompanies: [],
    parentCompanyListData: [],
    parentCompanySucccess: null,
    parentCompanySucccessWithoutRefresh: null,
    parentCompanySingleData: {},
    parentCompanyExists: null,
    parentCompanyDomainExists: null,
    parentCompanyErr: null,
    parentCompanyDeleteStatus: {},
    parentCompanyDownload: null,
};
export const getters = {
    parentCompanies: state => state.parentCompanies,
    parentCompanyListData: state => state.parentCompanyListData,
    parentCompanySucccess: state => state.parentCompanySucccess,
    parentCompanySucccessWithoutRefresh: state => state.parentCompanySucccessWithoutRefresh,
    parentCompanySingleData: state => state.parentCompanySingleData,
    parentCompanyExists: state => state.parentCompanyExists,
    parentCompanyDomainExists: state => state.parentCompanyDomainExists,
    parentCompanyErr: state => state.parentCompanyErr,
    parentCompanyDeleteStatus: state => state.parentCompanyDeleteStatus,
    parentCompanyDownload: state => state.parentCompanyDownload,
};

export const mutations = {
    setParentCompanies(state, newValue) {
        state.parentCompanies = newValue
    },
    setExportParentCompanyRecord(state, newValue) {
        state.parentCompanyDownload = newValue
    },
    setParentCompanyList(state, newValue) {
        state.parentCompanyListData = newValue
    },
    setParentCompanySuccess(state, newValue) {
        state.parentCompanySucccess = newValue
    },
    setParentCompanySuccessWithoutRefresh(state, newValue) {
        state.parentCompanySucccessWithoutRefresh = newValue
    },
    setParentCompanyExist(state, newValue) {
        state.parentCompanyExists = newValue
    },
    setParentCompanyDomainExist(state, newValue) {
        state.parentCompanyDomainExists = newValue
    },
    setSingleParentCompany(state, newValue) {
        state.parentCompanySingleData = newValue
    },
    setParentCompanyErr(state, newValue) {
        state.parentCompanyErr = newValue
    },
    setParentCompanyDeleteStatus(state, newValue) {
        state.parentCompanyDeleteStatus = newValue
    }
};

export const actions = {
    fetchParentCompanies({commit}, payload) {
        let isPayload = payload.action ? payload.action : "setParentCompanies";
        if (payload.action) {
            delete payload.action;
        }
        getParentCompanies(payload).then(res => {
            if(res && res.data) {
                commit(isPayload, res.data)
            }
        })
    },
    exportParentCompanyRecord({commit}, payload) {
        getParentCompaniesRecord(payload).then(res => {
            commit('setExportParentCompanyRecord', res.data)
        })
    },
    fetchSingleParentCompany({commit}, id) {
        getSingleParentCompany(id).then(res => {
            commit('setSingleParentCompany', res.data)
        })
    },
    isParentCompanyExists({commit}, payload) {
        parentCompanyIsExists(payload).then(res => {
            commit('setParentCompanyExist', res.data)
        }, () => {
            commit('setParentCompanyExist', true)
        })
    },
    // isParentCompanyDomainExists({commit}, payload) {
    //     parentCompanysDomainExists(payload).then(res => {
    //         commit('setParentCompanyDomainExist', res.data)
    //     }, () => {
    //         commit('setParentCompanyDomainExist', true)
    //     })
    // },
    postParentCompany({commit}, payload) {
        postParentCompanyApi(payload).then(res => {
            commit('setParentCompanySuccess', res.data)
        }, (error) => {
            commit('setParentCompanyErr', error.data)
        })
    },
    putParentCompany({commit}, payload) {
        putParentCompanyApi(payload).then(res => {
            (Object.hasOwn(payload, 'diff_state') ? commit('setParentCompanySuccessWithoutRefresh', res.data) : commit('setParentCompanySuccess', res.data));
        }, (error) => {
            commit('setParentCompanyErr', error.data)
        })
    },
    deleteParentCompany({commit}, payload) {
        deleteParentCompanyApi(payload).then(res => {
            commit('setParentCompanyDeleteStatus', res.data)
        }, (error) => {
            commit('setParentCompanyDeleteStatus', error.data)
        })
    },
    clearDeleteParentCompany({commit}) {
        commit('setParentCompanyDeleteStatus', null)
    },
    clearParentCompany({commit}) {
        commit('setParentCompanySuccessWithoutRefresh', null);
        commit('setParentCompanySuccess', null);
        commit('setParentCompanyErr', null);
        commit('setParentCompanies', null);
        commit('setParentCompaniesList', null);
        commit('setExportParentCompanyRecord', null);
    },
    clearIsParentCompanyExist({commit}) {
        commit('setParentCompanyExist', null);
    },
    clearIsParentCompanyDomainExist({commit}) {
        commit('setParentCompanyDomainExist', null);
    }
};
