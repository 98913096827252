/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import { getWidgetDataApi, getStatFieldApi, postStatFieldApi, putStatFieldApi } from "../../helpers/api";

export const state = {
    widgetData: [],
    statFields: [],
    statFieldsSuccess: null
};

export const getters = {
    widgetData: state => state.widgetData,
    statFields: state => state.statFields,
    statFieldsSuccess: state => state.statFieldsSuccess,
};

export const mutations = {
    setWidgetData(state, newValue) {
        state.widgetData = newValue
    },
    setStatFields(state, newValue) {
        state.statFields = newValue
    },
    setStatFieldsSuccess(state, newValue) {
        state.statFieldsSuccess = newValue
    },
};

export const actions = {
    fetchWidgetData({commit}, payload) {
        getWidgetDataApi(payload).then(res => {
            commit('setWidgetData', res.data)
        }, () => {
            commit('setWidgetData', [])
        })
    },
    getStatField({commit}, payload) {
        getStatFieldApi(payload).then(res => {
            commit('setStatFields', res && res.data ? res.data : [])
        }, () => {
            commit('setStatFields', [])
        })
    },
    postStatField({commit}, payload) {
        postStatFieldApi(payload).then(res => {
            commit('setStatFieldsSuccess', res.data)
        }, () => {
            commit('setStatFieldsSuccess', null)
        })
    },
    putStatField({commit}, payload) {
        putStatFieldApi(payload).then(res => {
            commit('setStatFieldsSuccess', res.data)
        }, () => {
            commit('setStatFieldsSuccess', null)
        })
    },
    clearStatField({commit}) {
        commit('setStatFieldsSuccess', null)
    },
    clearWidgetData({commit}) {
        commit('setWidgetData', []);
        commit('setStatFields', []);
    }
};
