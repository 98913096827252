/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {getAddOnApi, deleteAddOnApi, postAddOnApi, getSingleAddOn, putAddOnApi} from "../../helpers/api";

export const state = {
    addOns: [],
    addOnSuccess: {},
    addOnsDeleteStatus: {},
    addOnErr: {},
    addOnExists: null,
    addOnSingleData: {},
};

export const getters = {
    addOns: state => state.addOns,
    addOnSuccess: state => state.addOnSuccess,
    addOnsDeleteStatus: state => state.addOnsDeleteStatus,
    addOnErr: state => state.addOnErr,
    addOnExists: state => state.addOnExists,
    addOnSingleData: state => state.addOnSingleData,
};

export const mutations = {
    setAddOns(state, newValue) {
        state.addOns = newValue
    },
    setAddOnDeleteStatus(state, newValue) {
        state.addOnsDeleteStatus = newValue
    },
    setAddOnErr(state, newValue) {
        state.addOnErr = newValue
    },
    setAddOnExist(state, newValue) {
        state.addOnExists = newValue
    },
    setAddOnSuccess(state, newValue) {
        state.addOnSuccess = newValue
    },
    setSingleAddOn(state, newValue) {
        state.addOnSingleData = newValue
    },
};

export const actions = {
    fetchAddOns({commit}, payload) {
        getAddOnApi(payload).then(res => {
            commit('setAddOns', res.data)
        })
    },
    deleteAddOn({commit}, payload) {
        deleteAddOnApi(payload).then(res => {
            commit('setAddOnDeleteStatus', res.data)
        }, (error) => {
            commit('setAddOnDeleteStatus', error.data)
        })
    },
    clearAddOn({commit}) {
        commit('setAddOnSuccess', null);
        commit('setAddOnErr', null);
    },
    clearAddOnList({commit}) {
        commit('setAddOns', null);
    },
    postAddOn({commit}, payload) {
        postAddOnApi(payload).then(res => {
            commit('setAddOnSuccess', res.data)
        }, (error) => {
            commit('setAddOnErr', error.data)
        })
    },
    fetchSingleAddOn({ commit }, id) {
        getSingleAddOn(id).then(res => {
            commit('setSingleAddOn', res.data)
        })
    },
    putAddOn({ commit }, payload) {
        putAddOnApi(payload).then(res => {
            commit('setAddOnSuccess', res.data)
        }, (error) => {
            commit('setAddOnErr', error.data)
        })
    },
};
