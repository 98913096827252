/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    proposalExistsApi,
    getSingleProposal, postProposalApi, putProposalApi
} from "@/helpers/api";

export const state = {
    proposalSingleData: {},
    proposalSuccess: null,
    proposalErr:null,
    proposalExists: null,
};
export const getters = {
    proposalSingleData: state => state.proposalSingleData,
    proposalSuccess: state => state.proposalSuccess,
    proposalErr: state => state.proposalErr,
    proposalExists: state => state.proposalExists,

};

export const mutations = {

    setSingleProposal(state, newValue) {
        //console.log("newValue1",newValue)
        state.proposalSingleData = newValue
    },
    setProposalSuccess(state, newValue) {
        state.proposalSuccess = newValue
    },
    setProposalErr(state, newValue) {
        state.proposalErr = newValue
    },
    setProposalExist(state, newValue) {
        state.proposalExists = newValue
    },
};

export const actions = {

    fetchSingleProposal({commit}, id) {
        getSingleProposal(id).then(res => {
           let response={...{},...res.data};
           response.forecast_details.impressions=response.forecast_details.impressions/7
            commit('setSingleProposal', response)
        })
    },
    postProposal({commit}, payload) {
        postProposalApi(payload).then(res => {
            commit('setProposalSuccess', res.data)
        }, (error) => {
            commit('setProposalErr', error.data)
        })
    },
    putProposal({commit}, payload) {
        putProposalApi(payload).then(res => {
            commit('setProposalSuccess', res.data)
        }, (error) => {
            commit('setProposalErr', error.data)
        })
    },
    isProposalExists({commit}, payload) {
        proposalExistsApi(payload).then(res => {
            commit('setProposalExist', res.data)
        }, () => {
            commit('setProposalExist', true)
        })
    },
    clearProposal({commit}) {
        commit('setSingleProposal', {});
        commit('setProposalErr', {});
        commit('setProposalSuccess', {});
    },
    clearIsProposalExist({commit}) {
        commit('setProposalExist', null);
    },

};
