/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import { getActivityLogApi } from "../../helpers/api";

export const state = {
    activityLogList: [],
};

export const getters = {
    activityLogList: state => state.activityLogList,
};

export const mutations = {
    setActivityLogList(state, newValue) {
        state.activityLogList = newValue
    },
};

export const actions = {
    getActivityLog({commit}, payload) {
        getActivityLogApi(payload).then(res => {
            commit('setActivityLogList', res.data)
        }, () => {
            commit('setActivityLogList', [])
        })
    },
    clearActivityLog({commit}) {
        commit('setActivityLogList', [])
    },
};
