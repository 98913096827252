/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getProvidersApi,
} from "../../helpers/api";

export const state = {
    providers: [],
};

export const getters = {
    providers: state => state.providers,
};

export const mutations = {
    setProviders(state, newValue) {
        state.providers = newValue
    },
};

export const actions = {
    fetchProviders({commit}, payload) {
        getProvidersApi(payload).then(res => {
            commit('setProviders', res.data)
        })
    },

};
