/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    getVendors,
    postVendorApi,
    deleteVendorApi,
    getVendorSingleDataApi,
    putVendorApi,
    vendorExist,
    getVendorsRecord
} from "../../helpers/api";

export const state = {
    vendors: [],
    vendorSucccess: {},
    vendorSingleData: {},
    vendorExists: null,
    vendorsDownload: null,
    vendorErr: {},
    vendorDeleteStatus: {}
};


export const getters = {
    vendors: state => state.vendors,
    vendorSucccess: state => state.vendorSucccess,
    vendorSingleData: state => state.vendorSingleData,
    vendorExists: state => state.vendorExists,
    vendorErr: state => state.vendorErr,
    vendorDeleteStatus: state => state.vendorDeleteStatus,
    vendorsDownload: state => state.vendorsDownload,
};

export const mutations = {
    setVendors(state, newValue) {
        state.vendors = newValue
    },
    setExportVendorRecord(state, newValue) {
        state.vendorsDownload = newValue
    },
    setVendorSuccess(state, newValue) {
        state.vendorSucccess = newValue
    },
    setVendorExist(state, newValue) {
        state.vendorExists = newValue
    },
    setSingleVendors(state, newValue) {
        state.vendorSingleData = newValue
    },
    setVendorErr(state, newValue) {
        state.vendorErr = newValue
    },
    setVendorDeleteStatus(state, newValue) {
        state.vendorDeleteStatus = newValue
    }
};

export const actions = {
    fetchVendors({commit}, payload) {
        getVendors(payload).then(res => {
            commit('setVendors', res.data)
        })
    },
    fetchSingleVendors({commit}, id) {
        getVendorSingleDataApi(id).then(res => {
            commit('setSingleVendors', res.data)
        })
    },
    exportVendorRecord({commit}, payload) {
        getVendorsRecord(payload).then(res => {
            commit('setExportVendorRecord', res.data)
        })
    },
    isVendorExists({commit}, payload) {
        vendorExist(payload).then(res => {
            commit('setVendorExist', res.data)
        }, () => {
            commit('setVendorExist', true)
        })
    },
    postVendor({commit}, payload) {
        postVendorApi(payload).then(res => {
            commit('setVendorSuccess', res.data)
        }, (error) => {
            commit('setVendorErr', error.data)
        })
    },
    putVendor({commit}, payload) {
        putVendorApi(payload).then(res => {
            commit('setVendorSuccess', res.data)
        }, (error) => {
            commit('setVendorErr', error.data)
        })
    },
    deleteVendor({commit}, payload) {
        deleteVendorApi(payload).then(res => {
            commit('setVendorDeleteStatus', res.data)
        }, (error) => {
            commit('setVendorDeleteStatus', error.data)
        })
    },

    clearVendor({commit}) {
        commit('setVendorSuccess', null);
        commit('setVendorErr', null);
    },
    clearIsVendorExist({commit}) {
        commit('setVendorExist', null);
    },
    clearDeleteVendor({commit}) {
        commit('setVendorDeleteStatus', null);
    }
};
